import EventSourceStream from '@server-sent-stream/web'
import { useCallback, useState } from 'react'
import { IEventStreamResp } from '../types/kbase'

export function useEventStreamFetch<T = any>(loader) {
  const DefaultValue: IEventStreamResp = {
    loading: false,
    data: {
      chunk: '',
    },
  }
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<IEventStreamResp>(DefaultValue)

  const request = useCallback(
    async (payload: T) => {
      try {
        setLoading(true)
        setData((data) => ({
          ...data,
          ...DefaultValue,
          loading: true,
        }))
        const onDone = () => {
          setData((data) => ({
            ...data,
            done: true,
            loading: false,
          }))
          setLoading(false)
        }

        const response = await loader(payload)
        const decoder = new EventSourceStream()
        if (!response.body) return

        response.body.pipeThrough(decoder)
        const reader = decoder.readable.getReader()
        //TODO: kill it after changing page
        while (true) {
          const { value, ...args } = await reader.read()
          if (value.data === '[DONE]') {
            onDone()
            return
          }
          try {
            const parsedVal = JSON.parse(value.data)
            setData((data) => {
              data.data = data.data || {}
              if (typeof parsedVal?.chunk === 'string') {
                data.data.chunk += parsedVal.chunk
              } else {
                data.data = {
                  ...data.data,
                  ...parsedVal,
                }
              }
              return {
                ...data,
              }
            })
          } catch (e) {
            onDone()
            console.error(e)
          }
        }
      } catch (err) {
        setLoading(false)
        setData((prev) => ({
          ...prev,
          loading: false,
        }))
      }
    },
    [loader]
  )

  return { request, loading, data, setData }
}
