import { usePostUserInfo } from '@apis/hooks/user'
import { useHandleSignInCallback, useLogto } from '@logto/react'
import { redirectCache } from '@utils/cache'
import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

export default function useLogic() {
  const navigate = useNavigate()
  const { isAuthenticated, getIdTokenClaims } = useLogto()
  const { postUserInfo } = usePostUserInfo()
  const [params] = useSearchParams()
  const { isLoading } = useHandleSignInCallback(async () => {
    const userInfo = await getIdTokenClaims()
    postUserInfo({
      email: userInfo.email,
      profile_image_url: userInfo.picture,
      name: userInfo.name,
    })
    let redirect = params.get('redirect')
    redirect = (redirect && decodeURIComponent(redirect)) || redirectCache.get() || '/'
    try {
      const url = new URL(redirect)
      redirect = url.pathname + url.search
    } catch {
      redirect = '/'
    }
    navigate(redirect, { replace: true })
  })

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate('/')
    }
  }, [isAuthenticated])

  return isLoading
}
