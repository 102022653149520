import { Spin } from 'antd'
import useLogic from './useLogic'

export const Callback = () => {
  const isLoading = useLogic()

  if (isLoading) {
    return <Spin size="large" tip={_t('loggingIn')} fullscreen></Spin>
  }

  return (
    <div className="w-full flex justify-center mt-10" style={{ marginLeft: 100 }}>
      {_t('loginFailed')}
    </div>
  )
}
