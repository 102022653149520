import { API_HOST } from '@constants/api'
import { request } from '../utils/request'
import { Api } from './generated/api'

const apis = new Api({
  baseUrl: API_HOST,
  customFetch: request,
})

export default apis
