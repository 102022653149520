import { message } from 'antd'
import i18n from 'i18next'
import { useCallback } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { API_URL, FILE_API_URL } from '../../constants/api'
import { eventsMap } from '../../constants/events'
import { useAuthApi } from '../../hooks/useFetch'
import eventBus from '../../utils/event'
import { handleRequest } from '../../utils/request'
import {
  ICreateKbaseQuestBody,
  IKbaseDict,
  ILinkRequestBody,
  IMessageQuestBody,
  IQueryRequest,
  IUpdateKbaseQuestBody,
} from '../generated/model'

// Helper function to extract error code from error message
function extractErrCode(message: string): number | null {
  const match = message.match(/errorsMap\.(\d+)/)
  return match ? parseInt(match[1], 10) : null
}

function useRequest<T = any>() {
  const { authFetch, loading } = useAuthApi()

  async function request(method: string, path = '', params?: T) {
    const url = new URL(API_URL + `/kbase${path ? '/' + path : ''}`)

    return await handleRequest(
      authFetch(url, {
        method,
        json: params ?? {},
      })
    )
  }

  return { request, loading }
}

export function useGetResponseData() {
  const navigate = useNavigate()

  const getResponseData = useCallback(
    async (response: Response): Promise<[any, Error | null]> => {
      if (!response) {
        message.error(i18n.t('errorsMap.401'))
        navigate('/home')
        throw new Error('Unauthorized')
      }
      const status = response.status

      if (status === 500) {
        message.error(i18n.t('errorsMap.500'))
        throw new Error('Server error')
      } else if (status === 401) {
        message.error(i18n.t('errorsMap.401'))
        navigate('/home')
        throw new Error('Unauthorized')
      }

      const data = await response.json()

      if (!response.ok) {
        const error = new Error('errorsMap.' + data.err_code)
        message.error(i18n.t('errorsMap.' + data.err_code))
        throw error
      }
      return Promise.resolve([data, null])
    },
    [navigate]
  )

  return getResponseData
}

export function useSubmitLinkImport() {
  const { authFetch, loading } = useAuthApi()
  const params = useParams() // current kbase page

  const submitLinkImport = useCallback(
    async (payload: ILinkRequestBody) => {
      if (params.kid) {
        payload.kid = params.kid
      }

      if (!payload.kid) {
        delete payload.kid
      }

      return await handleRequest(
        authFetch(`${API_URL}/kbase/links`, {
          method: 'POST',
          json: payload,
        })
      )
    },
    [authFetch]
  )

  return { submitLinkImport, loading }
}

export function useSubmitFileImport() {
  const { authFetch, loading } = useAuthApi()
  const params = useParams()

  const submitFileImport = useCallback(
    async (file: File, kid = null) => {
      try {
        const formData = new FormData()
        if (params.kid) {
          formData.append('kid', params.kid)
        }
        if (kid) {
          formData.append('kid', kid)
        }
        formData.append('file', file)
        eventBus.emit(eventsMap.CREATE_KBASE_FILE_START)
        const [data, error] = await authFetch(`${FILE_API_URL}/kbase/files`, {
          method: 'POST',
          body: formData,
        })

        if (error) {
          console.error(error)
          return null
        }
        eventBus.emit(eventsMap.CREATE_KBASE_DONE, data)
        return data
      } catch (err) {
        console.error(err)
        throw err
      }
    },
    [authFetch, params.kid]
  )

  return { submitFileImport, loading }
}

export function useBaseQueryKbase(apiPath) {
  const { authFetch, loading } = useAuthApi(true)
  const [query] = useSearchParams()

  const queryKbase = useCallback(
    async (queryParameters: IQueryRequest) => {
      let retry = 3
      while (retry > 0) {
        try {
          const topic_id = query.get('topic_id') ?? ''
          if (topic_id) {
            queryParameters.topic_id = topic_id
          }
          const response = await authFetch(apiPath, {
            method: 'POST',
            json: {
              stream: 'true',
              ...queryParameters,
            },
          })
          return response
        } catch (error) {
          const errCode = extractErrCode((error as Error).message)
          if (errCode === 1010) {
            console.warn('err_code 1010 encountered, retrying in 1 second...')
            retry--
            await new Promise((resolve) => setTimeout(resolve, 1000))
            continue
          } else {
            console.error(error)
            throw error
          }
        }
      }
    },
    [authFetch, query, apiPath]
  )

  return { queryKbase, loading }
}

export function useQueryKbase() {
  return useBaseQueryKbase(API_URL + '/kbase/chat')
}

export function useGlobalQueryKbase() {
  return useBaseQueryKbase(API_URL + '/kbase/search_query')
}

export function useQueryKbaseList() {
  const { authFetch, loading } = useAuthApi()

  const queryKbaseList = useCallback(
    async (query?: any): Promise<{ kbases: IKbaseDict[] }> => {
      const url = new URL(API_URL + '/kbase')
      if (query) {
        Object.keys(query).forEach((key) => url.searchParams.append(key, query[key]))
      }
      return await handleRequest(
        authFetch(url, {
          method: 'GET',
        })
      )
    },
    [authFetch]
  )

  return { queryKbaseList, loading }
}

export function useListOriginals() {
  const { authFetch, loading } = useAuthApi()

  const listOriginals = useCallback(async () => {
    const url = new URL(API_URL + '/originals/')
    return await handleRequest(
      authFetch(url, {
        method: 'GET',
      })
    )
  }, [authFetch])

  return { listOriginals, loading }
}

export function useCreateKbase() {
  const { request, loading } = useRequest<ICreateKbaseQuestBody>()

  const createKbase = useCallback(async (params: ICreateKbaseQuestBody) => {
    return request('POST', '', params)
  }, [])

  return { createKbase, loading }
}

export function useDeleteKbase() {
  const { request, loading } = useRequest()

  const deleteKbase = useCallback(async (kid: string) => {
    return request('DELETE', kid)
  }, [])

  return { deleteKbase, loading }
}

export function useUpdateKbase() {
  const { request, loading } = useRequest()

  const updateKbase = useCallback(async (params: IUpdateKbaseQuestBody) => {
    return request('PATCH', '', params)
  }, [])

  return { updateKbase, loading }
}

export function useListMessages() {
  const { authFetch, loading } = useAuthApi()

  const listMessages = useCallback(
    async (messageQuery?: IMessageQuestBody) => {
      return await handleRequest(
        authFetch(API_URL + '/history/messages/', {
          method: 'POST',
          json: messageQuery,
        })
      )
    },
    [authFetch]
  )

  return { listMessages, loading }
}

export function useQueryKbaseRefs() {
  const { authFetch, loading } = useAuthApi()
  const queryKbaseRefs = useCallback(
    async (kid: string) => {
      return await handleRequest(
        authFetch(`${API_URL}/kbase/${kid}/references`, {
          method: 'GET',
        })
      )
    },
    [authFetch]
  )

  return { queryKbaseRefs, loading }
}

export function useQueryTask() {
  const { authFetch, loading } = useAuthApi()
  const queryTask = useCallback(
    async (taskId: string) => {
      return await handleRequest(
        authFetch(`${API_URL}/kbase/task?rid=${taskId}`, {
          method: 'GET',
        })
      )
    },
    [authFetch]
  )

  return { queryTask, loading }
}

export function useQueryImports() {
  const { authFetch, loading } = useAuthApi()
  const queryImports = useCallback(
    async (params?: { limit?: number; sort_by?: string }) => {
      return await handleRequest(
        authFetch(`${API_URL}/kbase/references`, {
          method: 'GET',
          params: params ?? {},
        })
      )
    },
    [authFetch]
  )

  return { queryImports, loading }
}

export function useQueryKbaseTotal() {
  const { authFetch, loading } = useAuthApi()
  const queryKbaseTotal = useCallback(async () => {
    return await handleRequest(
      authFetch(`${API_URL}/kbase/count`, {
        method: 'GET',
      })
    )
  }, [authFetch])

  return { queryKbaseTotal, loading }
}
