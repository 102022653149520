import { eventsMap } from '@constants/events'
import React from 'react'
import { ISearchHistoryDict, ITopicHistoryDict } from '../apis/generated/model'
import eventBus from '../utils/event'
import { getNewSearchTag } from '../utils/search'
import { importingTaskCache } from '@utils/cache'
import { ICreateProgressState } from '../types/kbase'
import useLanguage from './useLanguage'

interface ILayoutContext {
  currentTopic?: ITopicHistoryDict & { _isNewSearch?: boolean; _history?: Array<ISearchHistoryDict> }
  setCurrentTopic?: any

  importingTask?: any
  setImportingTask?: (task: any) => void
}

const layoutContext = React.createContext<ILayoutContext>({})
export default function useLayoutContext() {
  const context = React.useContext(layoutContext)

  return context
}

const isFinished = (status: ICreateProgressState) => {
  return [ICreateProgressState.complete, ICreateProgressState.failed].includes(status)
}

export function LayoutContextProvider(props) {
  useLanguage()
  const [currentTopic, setCurrentTopic] = React.useState<ITopicHistoryDict>(null)
  const [importingTask, setImportingTask] = React.useState(importingTaskCache.get())

  const updateImportingTask = (task) => {
    if (task?.type === 'close') {
      importingTaskCache.set(null)
      setImportingTask(null)
    } else if (task) {
      task._isProcessing = !isFinished(task.status)
      importingTaskCache.set(task)
      setImportingTask(task)
    }
  }
  const context = React.useMemo(() => {
    return {
      currentTopic,
      setCurrentTopic,
      importingTask,
      setImportingTask: updateImportingTask,
    }
  }, [currentTopic, importingTask])

  React.useEffect(() => {
    const unUpload = eventBus.on(eventsMap.UPLOAD_DONE, (data) => {
      updateImportingTask(data?.data)
    })

    return () => {
      unUpload()
    }
  }, [])

  React.useEffect(() => {
    const unbind = eventBus.on(eventsMap.SET_CURRENT_TOPIC, (topic: ITopicHistoryDict & { _isNewSearch?: boolean }) => {
      setCurrentTopic((cur) => {
        const isNewSearch = getNewSearchTag() === '1'
        if (topic) {
          topic._isNewSearch = isNewSearch
        }
        if (cur?.topic_id === topic?.topic_id)
          return {
            ...cur,
            ...topic,
          }
        return topic
      })
    })

    return unbind
  }, [])

  return <layoutContext.Provider value={context}>{props.children}</layoutContext.Provider>
}
