import { MoreOutlined } from '@ant-design/icons'
import { generateColorsFromFirstChar } from '@utils/index'
import { Avatar, Dropdown, MenuProps, message, Modal, Spin, Typography } from 'antd'
import { IKbaseDict } from '../../apis/generated/model'
import { eventsMap } from '../../constants/events'
import KbaseCardWrap from '../../pages/my-kbase/components/kbase-card-wrap'
import { IKbaseStatus } from '../../types/kbase'
import eventBus from '../../utils/event'
import KbaseSetting from '../kbase-setting'
import styles from './index.module.scss'
import useLogic from './useLogic'
import clsx from 'clsx'
import KbaseAvatar from '@components/kbase-avatar'
import { reportEventTracking } from '@utils/tracking'
import { useTranslation } from 'react-i18next'
const { Text } = Typography

enum EChangeType {
  update = 'update',
  delete = 'delete',
  none = 'none',
}
export default function KbaseCardItem(kbase: IKbaseDict) {
  const { navigate, form, deleteKbase, saveKbase, updating, items } = useLogic(kbase)
  const [modal, contextHolder] = Modal.useModal()
  const { t } = useTranslation()

  const saveKbaseHandler = async () => {
    reportEventTracking('my_kbase_update_kbase')
    await saveKbase()
    message.success(t('update_success'))
  }

  const onClick: MenuProps['onClick'] = (event) => {
    const { key } = event
    event.domEvent.preventDefault()
    event.domEvent.stopPropagation()
    reportEventTracking('my_kbase_operation', { type: key })
    switch (key) {
      case EChangeType.update:
        modal.info({
          title: t('kbase_setting'),
          icon: null,
          content: <KbaseSetting data={kbase} form={form} />,
          cancelText: t('cancel'),
          okText: t('save'),
          onOk: saveKbaseHandler,
          className: clsx(styles.kbaseModal, !kbase?.profile_image_url && styles.noAvatar),
          okButtonProps: {
            loading: updating,
            style: {
              borderRadius: 10,
            },
          },
          closable: true,
          width: 450,
        })
        return
      case EChangeType.delete:
        modal.confirm({
          title: t('deletion_confirmation'),
          content: t('delete_for_sure', { ...kbase }),
          okButtonProps: {
            shape: 'round',
          },
          cancelButtonProps: {
            shape: 'round',
          },
          async onOk() {
            await deleteKbase(kbase.kid)
            message.success(t('delete_success'))
            eventBus.emit(eventsMap.RELOAD_KBASE_LIST)
          },
        })
    }
  }

  return (
    <>
      <KbaseCardWrap
        onClick={() => {
          if (kbase.status === IKbaseStatus.Success) {
            reportEventTracking('my_kbase_navigate_to_kbase_chat')

            navigate(`/kbase/chat/${kbase.kid}`)
          }
        }}
      >
        {kbase?.kid && <KbaseInfo kbase={kbase} onClick={onClick} items={items} />}
      </KbaseCardWrap>
      {contextHolder}
    </>
  )
}

export function KbaseInfo(props) {
  const { kbase, items, onClick } = props
  const { t } = useTranslation()

  const renderStatus = () => {
    switch (kbase.status) {
      case IKbaseStatus.Processing:
        return <Spin tip={t('uploading')}></Spin>
      case IKbaseStatus.Fail:
        return <Spin tip={t('upload_failure')}></Spin>
      default:
        return null
    }
  }
  return (
    <div>
      {renderStatus()}
      <div className={'flex text-gray-400'}>
        <KbaseAvatar kbase={kbase} />
        <div className="flex-1 ml-2" style={{ marginTop: -1 }}>
          <Text style={{ width: 220 }} ellipsis={{ tooltip: kbase.nick_name }}>
            {kbase.nick_name}
          </Text>
        </div>

        <div
          className={styles.kbaseConfigBtn}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <Dropdown menu={{ items, onClick, className: styles.kbaseActions }}>
            <MoreOutlined style={{ fontSize: 18, fontWeight: 600, color: 'black' }} />
          </Dropdown>
        </div>
      </div>
      <Typography.Paragraph
        ellipsis={{
          rows: 2,
          // tooltip: kbase.description,
        }}
        className=" text-gray-500"
        style={{ height: 44, marginTop: 20 }}
      >
        {kbase.description}
      </Typography.Paragraph>
    </div>
  )
}
