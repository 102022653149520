import { getLang } from '@utils/request'
import React from 'react'
import { AuthContext } from '../layout/components/auth-provider'
import eventBus from '@utils/event'

export default function useLanguage() {
  const { user } = React.useContext(AuthContext)

  React.useEffect(() => {
    if (!user?.info) return
    eventBus.emit('change_language', user?.info?.language || getLang())
  }, [user])
}
