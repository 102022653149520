import React, { forwardRef, useImperativeHandle } from 'react'
import ReactDOM from 'react-dom'
import { runAnim } from '../../utils'
import eventBus from '../../utils/event'
import styles from './index.module.scss'

const eventName = 'anim_wrapper_start_anim'

const Portal: React.FC = ({ children, onCreate }) => {
  const portalRoot = document.createElement('div')
  portalRoot.style.position = 'fixed'
  document.body.appendChild(portalRoot)
  onCreate(portalRoot)
  return portalRoot ? ReactDOM.createPortal(children, portalRoot) : null
}

function AnimWrapper(props, ref) {
  const animStyleRef = React.useRef<any>(props.animOption ?? {})

  const emitAnim = (target = null) => {
    if (target) {
      animStyleRef.current.dest = target
    }
    eventBus.emit(eventName, animStyleRef.current)
  }

  const animHandler = (target = null) => {
    if (props.lazy) {
      setTimeout(() => {
        emitAnim(target)
      }, 50)
    } else {
      emitAnim(target)
    }
  }

  const onClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const x = event.clientX
    const y = event.clientY

    animStyleRef.current = { ...animStyleRef.current, x, y }
    if (!props.lazy) {
      emitAnim()
    }
  }

  useImperativeHandle(ref, () => ({
    run: animHandler,
  }))

  return (
    <>
      <div onClick={onClick}>{props.children}</div>
      <AnimPortal />
    </>
  )
}

export const AnimPortal = () => {
  const wrapperRef = React.useRef<HTMLDivElement>()

  React.useEffect(() => {
    const animHandler = (config) => {
      const $wrapper = wrapperRef.current

      if ($wrapper) {
        if (config) {
          $wrapper.style.display = 'block'
          $wrapper.style.top = `${config.y - 15}px`
          $wrapper.style.left = `${config.x - 15}px`
        }

        runAnim(wrapperRef.current, config)
      }
    }
    const unbind = eventBus.on(eventName, animHandler)

    return () => {
      wrapperRef.current?.remove()
      unbind()
    }
  }, [])

  return React.useMemo(
    () => (
      <Portal
        onCreate={(el) => {
          if (!wrapperRef.current) wrapperRef.current = el
        }}
      >
        <div className={styles.dot}></div>
      </Portal>
    ),
    []
  )
}

export default forwardRef(AnimWrapper)
