import mitt, { type Emitter } from 'mitt'

type IOnEvent = (...args: any[]) => () => void
interface IExtendedEmitter extends Emitter<any> {
  on: IOnEvent
  emitOnPage?: (...args: any[]) => void
  onPage?: IOnEvent
}
const eventBus: IExtendedEmitter = mitt() as IExtendedEmitter

const on = eventBus.on
eventBus.on = (...args): (() => void) => {
  on(...args)
  return () => {
    eventBus.off(...args)
  }
}

eventBus.onPage = (name, ...args) => {
  return eventBus.on(`${location.pathname}_${name}`, ...args)
}

eventBus.emitOnPage = (name, ...args) => {
  eventBus.emit(`${location.pathname}_${name}`, ...args)
}

export default eventBus
