import { Route, Routes } from 'react-router-dom'
import AdminLayout from './components/admin-layout'
import WhiteListUsersPage from './components/features/whitelist'
import ProtectedRoute from './components/protected-route'
import KbaseManagement from './components/features/kbase-management'
import Dashboard from './components/features/dashboard'
// import useLogic from "./useLogic";
export default function AdminPage() {
  // const { activeSortType, toggleSortType, loading, kbases } = useLogic();

  return (
    <div>
      <ProtectedRoute>
        <Routes>
          <Route path="/" element={<AdminLayout />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="whitelist-users" element={<WhiteListUsersPage />} />
            <Route path="kbase-management" element={<KbaseManagement />} />
          </Route>

          {/* Add more routes here */}
        </Routes>
      </ProtectedRoute>
    </div>
  )
}
