import { useCallback } from 'react'
import { API_URL } from '../../constants/api'
import { useAuthApi } from '../../hooks/useFetch'
import { useStreamFetch } from '../../hooks/useStreamFetch'
import { handleRequest } from '../../utils/request'
import { ICandidateRequest } from '../generated/model'

export function useWebSearch() {
  const { streamFetch, loading, data, setData } = useStreamFetch<ICandidateRequest>(`${API_URL}/kbase/web`)

  return { webSearch: streamFetch, loading, data, setData }
}

export function useQueryCandidate() {
  const { authFetch, loading } = useAuthApi()

  const searchCandidates = useCallback(
    async (params: ICandidateRequest) => {
      return await handleRequest(
        authFetch(`${API_URL}/kbase/candidate`, {
          method: 'POST',
          json: { stream: true, ...params },
        })
      )
    },
    [authFetch]
  )

  return { searchCandidates, loading }
}
