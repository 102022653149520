import { useLogto } from '@logto/react'
export const Logout = (props) => {
  const { signOut } = useLogto()

  const handleSignOut = async (url: string) => {
    await signOut(url)
  }

  return <button onClick={() => handleSignOut(`${window.location.origin}`)}>{props.children || 'Sign out'}</button>
}
