import React from 'react'
import { useSubmitLinkImport } from '../apis/hooks/kbase'
import eventBus from '../utils/event'
import { eventsMap } from '../constants/events'
import { isValidURL } from '../utils'
import { message } from 'antd'
import { IMPORT_TYPE } from '../types/kbase'

export default function useLinkImport() {
  const [input, setInput] = React.useState<string>('')
  const { submitLinkImport, loading: isCreating } = useSubmitLinkImport()

  const onSubmit = async (params = {}) => {
    if (!input?.trim()) return

    if (!isValidURL(input)) {
      message.error(_t('invalidLink'))
      return
    }
    eventBus.emit(eventsMap.CREATE_KBASE_LINK_START, {
      _importType: IMPORT_TYPE.LINK,
    })
    const resp = await submitLinkImport({
      links: [input],
      ...params,
    })

    setInput('')
    if (!resp) {
      eventBus.emit(eventsMap.UPLOAD_FAILED)
      return
    }
    eventBus.emit(eventsMap.UPLOAD_DONE, {
      data: resp,
      from: location.href,
    })
    eventBus.emit(eventsMap.CREATE_KBASE_DONE, resp)
    eventBus.emit(eventsMap.RELOAD_KBASE_LIST)
  }

  return {
    input,
    isCreating,
    onLinkChange: (e) => setInput(e.target.value),
    setInput,
    onSubmit,
  }
}
