import FavIcon from '@assets/icons/fav.svg'
import FavoredIcon from '@assets/icons/favored.svg'
import UnfavIcon from '@assets/icons/unfav.svg'
import styles from './index.module.scss'
import { Button } from 'antd'
import AnimWrapper from '@components/anim-wrapper'
import React from 'react'
import { reportEventTracking } from '@utils/tracking'
import apis from '@apis/index'
import eventBus from '@utils/event'
import { eventsMap } from '@constants/events'
import routes from '@constants/routes'
import { generatePath, useNavigate } from 'react-router-dom'

export default function FavButton(props) {
  const { topic, trackingName, animOption = {}, ...rest } = props
  const animRef = React.useRef()
  const { is_persist } = topic ?? {}
  const navigate = useNavigate()

  const favTopicHandler = (event) => {
    // event.preventDefault()
    // event.stopPropagation()
    reportEventTracking(trackingName ?? 'click_fav_topic_button')

    if (!topic) return
    let promise
    const { is_persist, topic_id } = topic
    if (is_persist) {
      promise = apis.resetSearchHistoryApiHistorySearchTopicIdResetPost(topic_id)
    } else {
      promise = apis.persistSearchHistoryApiHistorySearchTopicIdPersistPost(topic_id)
    }

    promise.then(() => {
      const target = is_persist ? '#temp-search-wrapper' : '#my-fav-search'

      animRef?.current?.run(target)
      eventBus.emit(eventsMap.REFRESH_TOPIC_LIST)
      if (topic_id === topic.topic_id) {
        const path = is_persist
          ? `${routes.TEMP_SEARCH}?topic_id=${topic_id}`
          : generatePath(routes.SEARCH, { topic_id })
        navigate(path, { replace: true })
      }
    })
  }

  if (!topic) return null

  return (
    <AnimWrapper lazy ref={animRef} animOption={animOption}>
      <Button type="link" onClick={favTopicHandler} className={styles.favBtn} {...rest}>
        {is_persist ? (
          <>
            <FavoredIcon />
            <UnfavIcon />
          </>
        ) : (
          <>
            <FavIcon />
            <FavoredIcon />
          </>
        )}
      </Button>
    </AnimWrapper>
  )
}
