import { eventsMap } from '@constants/events'
import EventSourceStream from '@server-sent-stream/web'
import eventBus from '@utils/event'
import { useCallback, useState } from 'react'
import { useAuthApi } from '../hooks/useFetch'
import { IStreamChunk } from '../types/kbase'
import useTopicId from './useTopicId'

const DefaultValue: IStreamChunk = {
  loading: false,
  data: {
    answer: '',
    count: 0,
    references: [],
  },
}

export function useStreamFetch<T = any>(url) {
  const { authFetch, loading } = useAuthApi(true)
  const [data, setData] = useState<IStreamChunk>(DefaultValue)
  const topic_id = useTopicId()

  const streamFetch = useCallback(
    async (payload: T) => {
      try {
        setData((data) => ({
          ...data,
          ...DefaultValue,
          loading: true,
        }))
        const onDone = () => {
          setData((data) => ({
            ...data,
            done: true,
            loading: false,
          }))
        }
        if (topic_id) {
          payload.topic_id = topic_id
        }
        const response = await authFetch(url, {
          method: 'POST',
          json: {
            stream: 'true',
            ...payload,
          },
        })
        eventBus.emit(eventsMap.WEB_SEARCH_CALLBACK)
        const decoder = new EventSourceStream()
        if (!response.body) return

        response.body.pipeThrough(decoder)
        const reader = decoder.readable.getReader()
        //TODO: kill it after changing page
        while (true) {
          const { value } = await reader.read()
          if (value.data === '[DONE]') {
            onDone()
            return
          }
          try {
            const parsedVal = JSON.parse(value.data)
            // console.log("parsed val", parsedVal);
            setData((data) => {
              if (parsedVal?.chunk) {
                data.data.answer += parsedVal.chunk
              } else {
                data.data = {
                  ...data.data,
                  ...parsedVal,
                }
              }
              return {
                ...data,
              }
            })
          } catch (e) {
            onDone()
            console.error(e)
          }
        }
      } catch (err) {
        setData((prev) => ({
          ...prev,
          loading: false,
        }))
      }
    },
    [authFetch, url, topic_id]
  )

  return { streamFetch, loading, data, setData }
}
