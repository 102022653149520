import { eventsMap } from '../constants/events'
import { parabolaAnim } from './anime'
import eventBus from './event'

export const runAnim = (src: any, opt?: any) => {
  const dest = opt?.dest ? document.querySelector(opt.dest) : document.getElementById('upload-history-wrapper')

  if (src && dest) {
    const parabola = parabolaAnim(src, dest, {
      ...opt,
      complete: () => {
        setTimeout(() => {
          src.style.display = 'none'
          eventBus.emit(eventsMap.CREATION_ANIM_COMPLETE)
        }, 50)
      },
    }).mark()
    parabola.init()
  }
}

function getCachedStorage(key: string) {
  try {
    const value = JSON.parse(sessionStorage.getItem(key))
    return value || {}
  } catch {
    return {}
  }
}

export const getCacheManager = (key) => {
  const cachedAnswers = getCachedStorage(key)
  return {
    get(keyword) {
      const target = cachedAnswers[keyword]

      if (target) {
        if (target.count <= 0) {
          delete cachedAnswers[keyword]
          this.update()
          return null
        } else {
          this.set(keyword, target.value, target.count - 1)
        }
      }
      return target?.value
    },
    set(keyword, value, count = 3) {
      cachedAnswers[keyword] = {
        value,
        count,
      }
      this.update()
      return cachedAnswers
    },
    update() {
      sessionStorage.setItem(key, JSON.stringify(cachedAnswers))
    },
  }
}

export function isValidURL(url) {
  const regex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i
  return regex.test(url)
}

export const getLocalStorageManager = (key) => {
  return {
    getItem() {
      const value = localStorage.getItem(key)
      try {
        return JSON.parse(value)
      } catch {
        return value
      }
    },
    setItem(value) {
      return localStorage.setItem(key, JSON.stringify(value))
    },
  }
}

export function generateColorsFromFirstChar(str: string): { backgroundColor: string; color: string } {
  if (!str || str.length === 0) {
    return { backgroundColor: '#000000', color: '#FFFFFF' }
  }

  const firstChar = str[0]
  const charCode = firstChar.charCodeAt(0)

  const randomSeed = charCode * 9301 + 49297
  const randomValue = Math.abs(Math.sin(randomSeed) * 10000) % 1

  const backgroundColor = `#${Math.floor(randomValue * 16777215)
    .toString(16)
    .padStart(6, '0')}`

  const r = parseInt(backgroundColor.substring(1, 3), 16)
  const g = parseInt(backgroundColor.substring(3, 5), 16)
  const b = parseInt(backgroundColor.substring(5, 7), 16)

  const brightness = (r * 299 + g * 587 + b * 114) / 1000

  const color = brightness < 128 ? '#FFFFFF' : '#000000'

  return { backgroundColor, color }
}
