import apis from '@apis/index'
import { MarkdownReply } from '@components/reply-md'
import { useEventStreamFetch } from '@hooks/useEventStreamFetch'
import { Skeleton } from 'antd'
import { useEffect } from 'react'
import { IOutputProps } from './types'
import { isFromHistory } from './utils'

export default function MindmapView(props: IOutputProps & { keywords: string[] }) {
  const { response_id, keywords } = props
  const { request, data, loading } = useEventStreamFetch(apis.transformKeywordToMindmapApiAnswerTransformMindmapPost)
  const isHistory = isFromHistory(props)
  const hasCache = 'mindmap' in props

  const fetchMindmap = async (response_id) => {
    await request({ response_id, keywords })
  }
  useEffect(() => {
    if (isHistory && hasCache) return

    if (response_id) {
      fetchMindmap(response_id)
    }
  }, [response_id])

  const renderReply = (chunk) =>
    chunk
      ?.replace?.('关键词及关系描述', '')
      .replace('关键词及其关系描述', '')
      .replace('Description of Keywords and Relationships', '')
      .replace('Description of Key Concepts and Relationships', '')

  if (isHistory && !hasCache) return null

  // enableG6={true}
  const renderMindmap = (chunk) => {
    return chunk && <MarkdownReply>{renderReply(chunk)}</MarkdownReply>
  }

  return (
    <div className="mb-6">
      {data?.loading || loading ? (
        <Skeleton active />
      ) : (
        <div>{isHistory ? renderMindmap(props.mindmap) : renderMindmap(data?.data?.chunk)}</div>
      )}
    </div>
  )
}
