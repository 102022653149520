import AnimWrapper from '@components/anim-wrapper'
import KbaseFileCreation from '@components/kbase-file-creation'
import { Button, Divider } from 'antd'
import React, { useEffect } from 'react'
import { IMPORT_TYPE } from '../../types/kbase'
import KbaseLinkImport, { CANCEL_LINK_IMPORT } from './kbase-link-import'
import eventBus from '../../utils/event'
import FileIcon from '@assets/icons/file.svg'
import LinkIcon from '@assets/icons/link.svg'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'

export default function KbaseImport(props) {
  const [importType, setImportType] = React.useState(IMPORT_TYPE.NIL)
  const animRef = React.useRef<any>()
  const reset = () => setImportType(IMPORT_TYPE.NIL)
  const { t } = useTranslation()

  useEffect(() => {
    return eventBus.on(CANCEL_LINK_IMPORT, reset)
  }, [])

  return (
    <AnimWrapper lazy ref={animRef} animOption={{ speed: 120 }}>
      <div style={{ marginBottom: 30 }}>
        <KbaseFileCreation
          onUploadStatusChange={(...args) => {
            const phase = args[0]
            if (phase === 'start') {
              animRef.current?.run()
            }
          }}
        >
          <Button
            type="text"
            icon={<FileIcon />}
            onClick={() => setImportType(IMPORT_TYPE.FILE)}
            className={styles.kbaseFileBtn}
            size="small"
          >
            {t('start_from_import_file')}
          </Button>
        </KbaseFileCreation>
        <Divider style={{ margin: '0 0 5px 0' }}></Divider>
        <div>
          <Button
            type="text"
            icon={<LinkIcon />}
            size="small"
            className={styles.kbaseLinkBtn}
            onClick={() => setImportType(IMPORT_TYPE.LINK)}
          >
            {t('start_from_import_link')}
          </Button>
        </div>
      </div>
      {importType === IMPORT_TYPE.LINK && <KbaseLinkImport onBeforeSubmit={animRef.current?.run} />}
    </AnimWrapper>
  )
}
