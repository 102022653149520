import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import { MermaidRenderer } from './mermaid'
import G6Render from './g6-render'

export default function MarkdownRender(config) {
  return (
    <Markdown
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || '')

          if (!inline && match && match[1] === 'mermaid') {
            return <MermaidRenderer chart={String(children).replace(/\n$/, '')} />
          } else if (match && match[1] === 'json' && config.enableG6) {
            try {
              const json = JSON.parse(children)
              return <G6Render input={json} />
            } catch {
              return null
            }
          }

          return (
            <code className={className} {...props}>
              {children}
            </code>
          )
        },
      }}
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      {...config}
    />
  )
}
