import { Button, Popover } from 'antd'
import React from 'react'
import { getLocalStorageManager } from '../../utils'

const KEY = 'tip_display_temp_search'
const storageHandler = getLocalStorageManager(KEY)

export default function SearchTip(props) {
  const [open, setOpen] = React.useState(false)

  const onClose = () => {
    storageHandler.setItem(true)
    setOpen(false)
  }

  const checkIfShouldOpen = () => {
    return props.open && !storageHandler.getItem()
  }

  React.useEffect(() => {
    if (checkIfShouldOpen()) {
      storageHandler.setItem(true)
      setOpen(true)
    }
  }, [props.open])

  return (
    <Popover
      open={open}
      placement="right"
      content={
        <div
          style={{
            maxWidth: 268,
          }}
        >
          <div className="flex mb-4 justify-center">
            <div>{_t('tip')}</div>
            <Button
              type="text"
              style={{ marginLeft: 'auto', padding: 0, height: 'auto' }}
              onClick={() => {
                onClose()
              }}
            >
              {_t('close')}
            </Button>
          </div>
          <div style={{ color: '#9F9F9F' }}>
            <div>{_t('newSearchPageTip')}</div>
            <ul style={{ listStyleType: 'disc', paddingLeft: 16 }}>
              <li>{_t('pageRetentionTip')}</li>
              <li>{_t('bookmarkTip')}</li>
            </ul>
          </div>
        </div>
      }
    >
      {props.children}
    </Popover>
  )
}
