import { isMobile } from 'react-device-detect'
import { createBrowserRouter, Outlet } from 'react-router-dom'
import AppLayout from './layout'
import { Callback } from './pages/callback'
import ErrorPage from './pages/error-page'
import Home from './pages/home'
import KbaseChat from './pages/kbase-chat'
import LoginPage from './pages/login'
import MyKbase from './pages/my-kbase'
import Search from './pages/search'
import SearchCommunity from './pages/search-community'
import UserSetting from './pages/user-setting'

import AuthLayout from './layout/AuthLayout'
import AdminPage from './pages/admin'
import AdminLoginPage from './pages/admin/components/admin-login'
import { MobileCallback } from './pages/callback/index.m'
import MobileHome from './pages/home/index.m'
import MobileKbaseChat from './pages/kbase-chat/index.m'
import MobileMyKbase from './pages/my-kbase/index.m'
import MobileSearch from './pages/search/index.m'
import MobileUserSetting from './pages/user-setting/index.m'
import routes from '@constants/routes'
import NotFound from './pages/not-found'
import { InvitePage } from './pages/invite'


export const router = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: routes.INVITE_PAGE,
        element: <InvitePage />,
      },
      {
        path: '/callback',
        element: <Callback />,
        // element: isMobile ? <MobileCallback /> : <Callback />,
      },
      {
        path: 'admin/login',
        element: <AdminLoginPage />,
      },
      {
        path: 'admin/*',
        element: <AdminPage />,
      },
      {
        path: '/login',
        element: <LoginPage />,
      },
      {
        element: <AuthLayout />,
        children: [
          {
            path: '/',
            index: true,
            element: <Home />,

            // element: isMobile ? <MobileHome /> : <Home />,
          },
          {
            path: '/user/setting',
            element: <UserSetting />,
            // element: isMobile ? <MobileUserSetting /> : <UserSetting />,
          },
          {
            path: routes.TEMP_SEARCH,
            // element: isMobile ? <MobileSearch /> : <Search />,
            element: <Search />,
          },
          {
            path: routes.SEARCH,
            element: <Search />,
            // element: isMobile ? <MobileSearch /> : <Search />,
          },
          {
            path: '/search-community',
            element: <SearchCommunity />,
          },
          {
            path: '/kbase/chat/:kid',
            element: <KbaseChat />,
            // element: isMobile ? <MobileKbaseChat /> : <KbaseChat />,
          },
          {
            path: '/my-kbase',
            element: <MyKbase />,
            // element: isMobile ? <MobileMyKbase /> : <MyKbase />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
])
