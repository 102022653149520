import { Affix, Button, Skeleton, Space, Typography } from 'antd'
import FaviconImage from '../favicon-image'
import styles from './index.module.css'
import React, { useEffect, useState } from 'react'
import { CloseCircleFilled } from '@ant-design/icons'
import eventBus from '@utils/event'
import clsx from 'clsx'
import { reportEventTracking } from '@utils/tracking'
interface IRefItem {
  datePublished: string
  name: string
  snippet: string
  title?: string
  url: string
  icon?: string
}
export function ReferenceItem(props: IRefItem) {
  return (
    <div
      className={styles.refItem}
      onClick={() => {
        reportEventTracking('reference_link_on_web_search.click')
        window.open(props.url)
      }}
    >
      <div>
        <FaviconImage iconLink={props.icon} />
      </div>
      <div
        className={clsx(styles.refName, 'flex items-center')}
        style={{
          marginBottom: 0,
          width: '100%',
          fontSize: 16,
          fontWeight: 500,
          color: '#000000',
        }}
      >
        {props.name}
      </div>
      {props.title && <div className={styles.refTitle}>{props.title}</div>}
      <div className={styles.refSnippet}>{props.snippet}</div>
    </div>
  )
}
export default function References(props: { list: IRefItem[] }) {
  const [loading, setLoading] = useState(true)
  const [container, setContainer] = React.useState<HTMLDivElement | null>(null)
  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }, [])

  return (
    <div className={styles.wrapper} ref={setContainer}>
      <Affix target={() => container}>
        <div style={{ textAlign: 'right' }}>
          <Button
            type="link"
            style={{ color: '#7E7C99' }}
            icon={<CloseCircleFilled style={{ fontSize: 20 }} />}
            onClick={() => {
              eventBus.emitOnPage('hide_view_more')
            }}
          />
        </div>
      </Affix>
      <div className={styles.listWrap}>
        {props.list?.map((item) => (
          <Skeleton key={item.url} loading={loading} active avatar>
            <ReferenceItem {...item} />
          </Skeleton>
        ))}
      </div>
    </div>
  )
}
