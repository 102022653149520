import { Button, Skeleton } from 'antd'
import type { IOutputProps } from './types'
import apis from '@apis/index'
import TableViewIcon from '@assets/icons/table-line.svg'
import { MarkdownReply } from '@components/reply-md'
import { useEventStreamFetch } from '@hooks/useEventStreamFetch'
import styles from './index.module.scss'

import { reportEventTracking } from '@utils/tracking'
import { isFromHistory } from './utils'

export default function TableView(props: IOutputProps) {
  const { response_id } = props
  const { request, data, loading } = useEventStreamFetch(apis.transformAnswerToTableApiAnswerTransformTablePost)
  const isHistory = isFromHistory(props)
  const hasCache = 'table' in props

  const tableViewHandler = async () => {
    reportEventTracking('search_table_view.click')
    try {
      await request({ response_id })
    } catch {
      //todo
    }
  }

  // useEffect(() => {
  //   if (isHistory) return

  //   if (response_id) {
  //     tableViewHandler()
  //   }
  // }, [response_id])

  const isLoading = data?.loading || loading

  return (
    <div className="mb-6">
      {!isLoading && !data?.data?.chunk && !hasCache && (
        <div className="flex px-3">
          <Button type="link" disabled={isLoading} onClick={tableViewHandler} className="p-0" style={{gap: 5}}>
            <TableViewIcon /> <span style={{ fontSize: 14, color: '#1E2336' }}>{_t('chartExplanation')}</span>
          </Button>
        </div>
      )}
      {isLoading ? (
        <Skeleton active />
      ) : (
        <div className={styles.tableView}>
          {isHistory ? (
            hasCache ? (
              <MarkdownReply>{props.table}</MarkdownReply>
            ) : null
          ) : (
            data?.data?.chunk && <MarkdownReply>{data?.data?.chunk}</MarkdownReply>
          )}
        </div>
      )}
    </div>
  )
}
