import { generateColorsFromFirstChar } from '@utils/index'
import { Avatar } from 'antd'

export default function KbaseAvatar(props) {
  const { kbase, ...rest } = props
  if (!kbase) return null
  return (
    <Avatar
      size="large"
      src={kbase?.profile_image_url}
      style={{
        ...(!kbase?.profile_image_url && generateColorsFromFirstChar(kbase.nick_name)),
        verticalAlign: 'middle',
      }}
      {...rest}
    >
      {kbase.nick_name?.[0]}
    </Avatar>
  )
}
