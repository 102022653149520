import { MarkdownReply } from '@components/reply-md'
import { Card, Divider, Skeleton } from 'antd'
import styles from '../index.module.scss'
import { useEventStreamFetch } from '@hooks/useEventStreamFetch'
import apis from '@apis/index'
import { useEffect } from 'react'
import type { IAnswerProps } from '../types'

export default function AskMoreAnswer(props: IAnswerProps) {
  const { request, data, loading } = useEventStreamFetch(
    apis.transformAnswerToMoreDetailsApiAnswerTransformMoreDetailsPost
  )

  useEffect(() => {
    if (props.answer._more_details) return

    request({
      response_id: props.response_id,
      ans_blk_id: props.answer.ans_blk_id,
    })
  }, [])
  
  return (
    <div className={styles.ansBlock}>
      <Divider />
      <Card
        title={_t('ask_more')}
        loading={false}
        bordered={false}
        classNames={{
          header: styles.header,
        }}
      >
        <div>
          {loading ? (
            <Skeleton />
          ) : (
            <MarkdownReply>{data?.data?.chunk || props.answer?._more_details?.reply}</MarkdownReply>
          )}
        </div>
      </Card>
    </div>
  )
}
