import type { UploadFile, UploadProps } from 'antd'
import { message, Upload } from 'antd'
import { useState } from 'react'
import { useSubmitFileImport } from '../../apis/hooks/kbase'
import eventBus from '../../utils/event'
import { eventsMap } from '../../constants/events'
import { IMPORT_TYPE } from '../../types/kbase'
import { reportEventTracking } from '@utils/tracking'
import { useTranslation } from 'react-i18next'

export default function KbaseFileCreation(props) {
  const { onUploadStatusChange, ...restProps } = props
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const { submitFileImport } = useSubmitFileImport()
  const { t } = useTranslation()

  const handleUpload = async (file) => {
    reportEventTracking('kbase_import_by_file', { page: location.pathname })
    onUploadStatusChange?.('start', {
      _importType: IMPORT_TYPE.FILE,
      file,
      uid: file.uid,
      size: file.size / 1024 / 1024,
      nick_name: file.name,
      type: file.type,
    })

    submitFileImport(file, props.kid)
      .then((res) => {
        eventBus.emit(eventsMap.UPLOAD_DONE, {
          data: res,
          from: location.href,
        })
        eventBus.emit(eventsMap.RELOAD_KBASE_LIST)
        message.success(t('uploadSuccess'))
        onUploadStatusChange?.('success', res)
      })
      .catch((err) => {
        console.log(err)
        message.error(t('uploadError'))
        eventBus.emit(eventsMap.UPLOAD_FAILED)
        onUploadStatusChange?.('error', err)
      })
      .finally(() => {
        setFileList([])
        onUploadStatusChange?.('done')
      })
  }

  const uploadConfig: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file])
      return false
    },
    onChange: ({ file }) => {
      handleUpload(file)
    },
    onDrop: () => {},
    fileList,
    showUploadList: false,
    disabled: fileList.length > 0,
    ...restProps,
  }

  const { children, ...rest } = props
  return (
    <Upload accept=".pdf,.pptx,.epub,.doc,.docx,.md,.htm,.html,.csv,.xml" {...uploadConfig} {...rest}>
      {children}
    </Upload>
  )
}
