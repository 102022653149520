import FileIcon from '@assets/icons/file.svg'
import LinkIcon from '@assets/icons/link.svg'
import { Typography } from 'antd'
import { Link } from 'react-router-dom'
import styles from './index.module.scss'
const { Paragraph } = Typography

export default function Reference(props) {
  const { task, shortRid } = props
  // console.log('task', task)
  return (
    <Link to={`/kbase/chat/${task.kid}?_rid=${shortRid}`} className={styles.uploadInput}>
      <span style={{ color: '#FFA423', marginRight: 6 }}>
        {task.imported_type === 'file' ? <FileIcon /> : <LinkIcon />}
      </span>

      <Paragraph
        style={{ marginBottom: 0, flex: 1, marginRight: 3 }}
        ellipsis={{
          rows: 1,
          expandable: false,
        }}
        title={task.title}
      >
        {task.title}
      </Paragraph>
    </Link>
  )
}
