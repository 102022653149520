import { List, Popover } from 'antd'
import clsx from 'clsx'
import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { IOriginalDict } from '../../../apis/generated/model'
import { useQueryImports } from '../../../apis/hooks/kbase'
import { ICreateProgressState } from '../../../types/kbase'
import styles from './index.module.scss'
import Reference from './reference'
import eventBus from '@utils/event'
import { eventsMap } from '@constants/events'

export default function UploadList() {
  const { queryImports, loading } = useQueryImports()
  const [list, setList] = React.useState<IOriginalDict[]>([])
  const params = useParams()
  const [query] = useSearchParams()

  const queryHandler = async () => {
    const data = await queryImports()
    setList(data?.references ?? [])
  }

  React.useEffect(() => {
    queryHandler()

    eventBus.on(eventsMap.CREATE_KBASE_SUCCESS, queryHandler)
  }, [])

  const filteredList = list.filter((ref) =>
    [ICreateProgressState.complete].includes(ref.status as ICreateProgressState)
  )
  return (
    <div
      style={{
        backgroundColor: '#FBF6F6',
        borderRadius: 10,
        zIndex: 1,
        padding: 10,
        position: 'absolute',
        bottom: 0,
        top: 112,
        left: 4,
        right: 4,
      }}
    >
      <List
        header={
          <div style={{ marginTop: -10 }}>
            <div style={{ color: '#7E7C99', fontSize: 14, fontWeight: 600 }}>
              {_t('uploadedLinksFiles', { count: filteredList.length })}
            </div>
            <div style={{ color: '#9F9F9F', fontSize: 12 }}>{_t('clickToJump')}</div>
          </div>
        }
        loading={loading}
        dataSource={filteredList}
        style={{ maxHeight: '70vh', overflow: 'auto' }}
        renderItem={(ref) => {
          const shortRid = ref.rid.slice(3, 10)
          return (
            <List.Item
              style={{ padding: '7px 5px', border: 'none' }}
              className={clsx(shortRid === query.get('_rid') && styles.active, styles.task)}
            >
              <Popover
                placement="right"
                content={ref.title}
                overlayStyle={{ maxWidth: 250, wordWrap: 'break-word' }}
                overlayInnerStyle={{ borderRadius: 8 }}
              >
                <List.Item.Meta description={<Reference task={ref} shortRid={shortRid} />} />
              </Popover>
            </List.Item>
          )
        }}
      />
    </div>
  )
}
