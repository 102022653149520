import React from 'react'
import { useQueryKbaseList } from '../apis/hooks/kbase'
import { IKbaseDict } from '../apis/generated/model'

export default function useGetKbase(kid: string | undefined) {
  const { queryKbaseList, loading } = useQueryKbaseList()
  const [kbase, setKbase] = React.useState<IKbaseDict>()

  React.useEffect(() => {
    if (!kid?.trim()) return

    queryKbaseList({ filter_by: 'kid', keyword: kid }).then((data) => {
      setKbase(data?.kbases?.[0])
    })
  }, [kid])

  return { kbase, loading }
}
