import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ConfigProvider } from 'antd'
import { useTranslation } from 'react-i18next'
import { RouterProvider } from 'react-router-dom'
import './index.css'
import { router } from './routes'
import './utils/sentry'
// import esES from 'antd/lib/locale/es_ES'
import eventBus from '@utils/event'
import enUS from 'antd/lib/locale/en_US'
import zhCN from 'antd/lib/locale/zh_CN'
import React, { useEffect } from 'react'
import './i18n/lazy'
import { langCache } from '@utils/cache'
import { Language } from '@constants/env'


function useLocale() {
  const { i18n } = useTranslation()
  const [locale, setLocale] = React.useState(i18n.language)
  useEffect(() => {
    return eventBus.on('change_language', (lang) => {
      if (i18n.language !== lang && Object.values(Language).includes(lang)) {
        langCache.set(lang)
        setLocale(lang)
        i18n.changeLanguage(lang)
      }
    })
  }, [i18n])
  return locale
}

export default function App() {
  const { t } = useTranslation()
  const queryClient = new QueryClient()
  const locale = useLocale()
  const currentLocale = locale === Language.CN ? zhCN : enUS

  if (!window._t) {
    window._t = t
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        locale={currentLocale}
        theme={{
          components: {
            Layout: {
              headerBg: 'white',
              bodyBg: 'transparent',
            },
          },
          token: {
            // Seed Token
            colorPrimary: '#F9F9F9',
            borderRadius: 2,
            // Alias Token
            // colorBgContainer: "#f6ffed",
          },
        }}
      >
        <RouterProvider router={router} />
      </ConfigProvider>
    </QueryClientProvider>
  )
}
