export const redirectCache = {
  key: 'cache_redirect_url',
  set() {
    sessionStorage.setItem(this.key, location.href)
  },

  get() {
    const tag = sessionStorage.getItem(this.key)
    this.remove()
    return tag
  },

  remove() {
    sessionStorage.removeItem(this.key)
  },
}

export const welcomeModalCache = {
  key: 'cache_welcome_modal',
  set() {
    localStorage.setItem(this.key, 'true')
  },

  get() {
    const value = localStorage.getItem(this.key)
    return !!value
  },
}

export const importingTaskCache = {
  key: 'cache_importing_task',
  set(task) {
    if (!task) {
      localStorage.removeItem(this.key)
    } else {
      localStorage.setItem(this.key, JSON.stringify(task))
    }
  },

  get() {
    try {
      const value = JSON.parse(localStorage.getItem(this.key))
      if (!value?.title) return null
      return value
    } catch {
      return null
    }
  },
}

export const userInfoCache = {
  key: 'cache_user',
  set(task) {
    if (!task) {
      localStorage.removeItem(this.key)
    } else {
      localStorage.setItem(this.key, JSON.stringify(task))
    }
  },

  get() {
    try {
      const value = JSON.parse(localStorage.getItem(this.key))
      if (!value?.title) return null
      return value
    } catch {
      return null
    }
  },
}

export const langCache = {
  key: 'cache_lang',
  set(data) {
    if (!data) {
      localStorage.removeItem(this.key)
    } else {
      localStorage.setItem(this.key, JSON.stringify(data))
    }
  },

  get() {
    try {
      const value = JSON.parse(localStorage.getItem(this.key))
      return value
    } catch {
      return null
    }
  },
}

export const userGuideMoreButtonCache = {
  key: 'cache_user_guide_more_button',
  set(data) {
    if (!data) {
      localStorage.removeItem(this.key)
    } else {
      localStorage.setItem(this.key, data)
    }
  },

  get() {
    try {
      const value = JSON.parse(localStorage.getItem(this.key))
      return value
    } catch {
      return null
    }
  },
}
