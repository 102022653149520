import useLinkImport from '@hooks/useLinkImport'
import { Button, Input, message } from 'antd'
import { isValidURL } from '../../utils'
import styles from './index.module.scss'
import eventBus from '../../utils/event'
import { reportEventTracking } from '@utils/tracking'
import { useTranslation } from 'react-i18next'

export const CANCEL_LINK_IMPORT = 'CANCEL_LINK_IMPORT'
export default function KbaseLinkImport(props) {
  const { onLinkChange, onSubmit, input, isCreating } = useLinkImport()
  const { t } = useTranslation()

  const onCancel = () => {
    eventBus.emit(CANCEL_LINK_IMPORT)
  }
  const onImportLink = async () => {
    reportEventTracking('import_kbase_by_link', { page: location.pathname })

    if (!input.trim()) return
    if (!isValidURL(input.trim())) {
      message.error(t('invalid_link'))
      return
    }

    props.onBeforeSubmit?.()
    const params = {}
    if (props.kid) {
      params.kid = props.kid
    }
    await onSubmit(params)
    reportEventTracking('import_kbase_by_link_success', { page: location.pathname })
    onCancel()
  }

  return (
    <div className={styles.linkInput}>
      <Input onChange={onLinkChange} placeholder={t('paste_link')} style={{ borderRadius: 100, height: 36 }} />
      <div className="flex mt-3 gap-3">
        <Button onClick={onImportLink} style={{ width: '100%', borderRadius: 100 }} loading={isCreating}>
          {t('import')}
        </Button>
        <Button onClick={onCancel} style={{ width: '100%', borderRadius: 100 }}>
          {t('cancel')}
        </Button>
      </div>
    </div>
  )
}
