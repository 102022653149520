import { Button } from 'antd'
import { LoginWrap } from './login-wrap'
import useSignIn from '@hooks/useSignIn'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import routes from '@constants/routes'
import { redirectCache } from '@utils/cache'
import { isMobile } from 'react-device-detect'
export default function LoginPage() {
  const signIn = useSignIn()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (location.pathname !== routes.LOGIN) {
      redirectCache.set()
      navigate(`${routes.LOGIN}?redirect=${encodeURIComponent(location.href)}`, { replace: true })
    }
  }, [])

  return (
    <div className="w-full h-screen flex justify-center flex-col items-center">
      <div style={isMobile ? { fontSize: 70 } : { fontSize: 82 }}>Hello Hika</div>
      <div style={isMobile ? { fontSize: 22 } : { fontSize: 32, fontWeight: 600 }}>{_t('hikaSlogan')}</div>
      <LoginWrap>
        <Button
          type="primary"
          shape="round"
          onClick={signIn}
          style={{
            marginTop: 70,
            backgroundColor: '#000000',
            borderRadius: 100,
            width: 188,
            height: 68,
            lineHeight: '68px',
            color: '#F9F9F9',
            fontSize: 22,
            fontWeight: 700,
            marginBottom: 30,
          }}
        >
          {_t('loginToUse')}
        </Button>
      </LoginWrap>
    </div>
  )
}
