import CloseIcon from '@assets/icons/close-fill.svg'
import FavButton from '@components/fav-button'
import { eventsMap } from '@constants/events'
import routes from '@constants/routes'
import useTopicId from '@hooks/useTopicId'
import { reportEventTracking } from '@utils/tracking'
import { Menu, Typography } from 'antd'
import clsx from 'clsx'
import { generatePath, Link, useNavigate } from 'react-router-dom'
import apis from '../../apis'
import eventBus from '../../utils/event'
import styles from './index.module.scss'

export default function SearchTopics(props) {
  const { topics = [] } = props
  const topic_id = useTopicId()
  const navigate = useNavigate()

  const deleteTopicHandler = async (e, topicId) => {
    reportEventTracking('temp_search_delete_topic')
    e.preventDefault()
    e.stopPropagation()

    await apis.removeTopicSearchHistoryApiHistorySearchTopicIdDelete(topicId)
    eventBus.emit(eventsMap.REFRESH_TOPIC_LIST)
    if (topic_id === topicId) {
      navigate(routes.HOME, { replace: true })
    }
  }

  return (
    <Menu
      mode="inline"
      className={styles.searchLogs}
      style={{
        backgroundColor: 'transparent',
        fontWeight: 500,
        flex: 'auto',
        marginTop: 15,
        overflowY: 'auto',
        padding: 0,
        border: 'none',
      }}
      selectedKeys={topic_id ? [topic_id] : []}
      items={topics?.map((topic) => ({
        label: (
          <div className={clsx('flex items-center', styles.topicItem)}>
            <Typography.Paragraph style={{ width: '100%' }} ellipsis>
              <Link
                onClick={() => {
                  reportEventTracking('temp_search_click_topic_title')
                }}
                to={`${topic.is_persist ? generatePath(routes.SEARCH, { topic_id: topic.topic_id }) : routes.TEMP_SEARCH}?topic_id=${topic.topic_id}`}
              >
                {topic.title}
              </Link>
            </Typography.Paragraph>

            <FavButton
              topic={topic}
              style={{ marginRight: 5, marginLeft: 5, zIndex: 9 }}
              trackingName="click_fav_topic_button_on_temp_menu"
              animOption={{ speed: 50 }}
            />
            <div
              className={styles.icon}
              style={{ marginLeft: 6 }}
              onClick={(e) => deleteTopicHandler(e, topic.topic_id)}
            >
              <CloseIcon />
            </div>
          </div>
        ),
        key: topic.topic_id,
      }))}
    />
  )
}
