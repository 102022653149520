import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useListMessages, useQueryKbase } from '../../apis/hooks/kbase'
import useGetKbase from '../../hooks/useGetKbase'
import { AuthContext } from '../../layout/components/auth-provider'
import { getLang, pipeResponse, requestFn } from '../../utils/request'
import { ProChatInstance } from '@ant-design/pro-chat'
import { IRoleType } from './types'
import { IKbaseQuota, IMessageQuestBody } from '../../apis/generated/model'
import apis from '@apis/index'
import { reportEventTracking } from '@utils/tracking'

const defaultPageInfo = {
  page: 1,
  limit: 100,
}
export default function useLogic() {
  const { kid } = useParams()
  const { user } = useContext(AuthContext)
  const [isVisible, setIsVisible] = React.useState(false)
  const { queryKbase } = useQueryKbase()
  const { kbase } = useGetKbase(kid)
  const { listMessages, loading } = useListMessages()
  const [messages, setMessages] = React.useState([])
  const [pageInfo, setPageInfo] = React.useState(defaultPageInfo)
  const proChatRef = React.useRef<ProChatInstance>()
  const [fetching, setFetching] = React.useState(false)
  const [quota, setQuota] = React.useState(null)

  const queryAnswer = async (messages) => {
    reportEventTracking('kbase_chat_user_query')

    try {
      const response = await queryKbase({
        kid,
        q: messages[messages.length - 1].content,
        language: getLang(),
      })
      return pipeResponse(response)
    } catch (err) {
      console.log(err)
      return _t('systemError')
    }
  }

  const queryMessagesHistory = async (params?: Partial<IMessageQuestBody>) => {
    if (fetching) return
    let prev = -10000
    let element = null
    function scrollToBottom() {
      setTimeout(() => {
        element = element || document.querySelector('.ant-pro-chat-list')
        if (!element) return
        proChatRef.current?.scrollToBottom()
        if (element.scrollHeight === prev) return
        prev = element.scrollHeight
        scrollToBottom()
      }, 200)
    }

    try {
      setFetching(true)
      const { messages } = await listMessages({ kid, ...params })
      setMessages((curMessages) => {
        let chatId = 0
        messages.reverse()
        const newMsgs = messages
          .map((message) => {
            return [
              {
                id: 'chat-' + chatId++,
                content: message.query,
                role: IRoleType.User,
                updateAt: message.timestamp,
                createAt: message.timestamp,
              },
              {
                id: 'chat-' + chatId++,
                content: message.response,
                role: IRoleType.Assistant,
                updateAt: message.timestamp,
                createAt: message.timestamp,
              },
            ]
          })
          .flat()
        return [...newMsgs, ...curMessages]
      })

      scrollToBottom()
    } catch (err) {
      console.log(err)
      return _t('systemError')
    } finally {
      setFetching(false)
    }
  }

  const requestPreviousPage = async () => {
    if (fetching) return

    setPageInfo((pageInfo) => {
      pageInfo.page = pageInfo.page + 1
      queryMessagesHistory(pageInfo)
      return { ...pageInfo }
    })
  }

  React.useEffect(() => {
    if (kid) {
      queryMessagesHistory(defaultPageInfo)
      requestFn(apis.listKbaseReferenceQuoteApiKbaseQuotaGet).then((res: IKbaseQuota) => {
        for (const item of res.kbase_quota_list) {
          if (item.kid === kid) {
            item.left = item.left <= 0 ? 0 : item.left
            item.left = 10
            setQuota(item)
            break
          }
        }
      })
    } else {
      setPageInfo(() => defaultPageInfo)
    }
  }, [kid])

  return {
    user,
    kid,
    kbase,
    isVisible,
    setIsVisible,
    queryAnswer,

    messages,
    setMessages,
    loading,
    queryMessagesHistory,
    requestPreviousPage,

    proChatRef,

    quota,
  }
}
