import { Spin, Upload } from 'antd'
import { useUploadImage } from '../../apis/hooks/common'
import styles from './index.module.css'
import clsx from 'clsx'
export default function UploadImage(props: any) {
  const { style } = props
  const { uploadImage, loading } = useUploadImage()
  return (
    <Upload
      name="file"
      showUploadList={false}
      action="/api/upload/images"
      listType="picture-circle"
      className={clsx('relative', styles.uploadWrapper)}
      style={style}
      customRequest={async (options) => {
        const ret = await uploadImage(options)
        props.onChange?.(ret)
        return ret
      }}
    >
      {loading && (
        <div className={styles.spinner}>
          <Spin spinning={loading} />
        </div>
      )}
      {props.children}
    </Upload>
  )
}
