import { Button, Form, Input, message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { validateAdminHandler } from '../utils'

const AdminLoginPage = () => {
  const navigate = useNavigate()

  const onFinish = async (values) => {
    await validateAdminHandler(values, (isSuccess) => {
      if (isSuccess) {
        navigate('/admin')
        return
      } else {
        message.error(_t('wrongCredentials'))
      }
    })

    // Here you should add your authentication logic (e.g., call an API to verify credentials)
    // if (values.username === "admin" && values.password === "admin") {
    //   localStorage.setItem("authToken", "your-token-here");
    //   navigate("/admin");
    // }
  }

  return (
    <div className=" bg-white ">
      <div className="w-1/4 mx-auto p-10">
        <Form onFinish={onFinish}>
          <Form.Item name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
            <Input placeholder="Username" />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
            <Input.Password placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default AdminLoginPage
