import { langCache } from '@utils/cache'
import { getLang } from '@utils/request'

export const isDev = process.env.NODE_ENV === 'development'

export enum Language {
  EN = 'en',
  CN = 'zh-CN',
}

export function getLocale() {
  const lang = langCache.get() || getLang()
  return Object.values(Language).includes(lang) ? lang : Language.EN
}
