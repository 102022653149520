import { Avatar, Typography } from 'antd'
import { Link } from 'react-router-dom'

const hashStringToColor = (str) => {
  if (!str) return ''
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  const r = (hash & 0xff0000) >> 16
  const g = (hash & 0x00ff00) >> 8
  const b = hash & 0x0000ff
  const clamp = (value) => Math.max(0, Math.min(255, value))
  return `rgb(${clamp(r)}, ${clamp(g)}, ${clamp(b)})`
}

function getContrastColor(hex) {
  // Convert hex to RGB
  const r = parseInt(hex.substring(1, 3), 16)
  const g = parseInt(hex.substring(3, 5), 16)
  const b = parseInt(hex.substring(5, 7), 16)

  // Calculate luminance
  const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255

  // Return white or black based on luminance
  return luminance > 0.5 ? '#000000' : '#FFFFFF'
}

function getInitials(username) {
  return username?.slice(0, 2).toUpperCase()
}

export default function UserAvatar(props) {
  const { user } = props
  const bgColor = hashStringToColor(user?.name)
  const avatarUrl = user?.avatarUrl
  return (
    <Link to="/user/setting" className="w-full h-full flex items-center">
      <Avatar
        src={avatarUrl}
        style={{
          width: 50,
          height: 50,
          color: !avatarUrl && getContrastColor(bgColor),
          backgroundColor: !avatarUrl && bgColor,
        }}
      >
        {getInitials(user?.name)}
      </Avatar>
      <div style={{ flex: 1 }}>
        <Typography.Text
          ellipsis
          style={{
            fontSize: 14,
            color: '#7E7C99',
            fontWeight: 600,
            maxWidth: 138,
            marginLeft: 5,
          }}
        >
          {user?.name}
        </Typography.Text>
        <Typography.Text
          ellipsis
          style={{
            fontSize: 12,
            color: '#7E7C99',
            maxWidth: 138,
            marginLeft: 5,
          }}
        >
          {user?.email || user?.name}
        </Typography.Text>
      </div>
    </Link>
  )
}
