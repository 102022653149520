import { CloseCircleOutlined } from '@ant-design/icons'
import { List, Spin, Typography } from 'antd'
import React from 'react'
import { useQueryKbaseRefs } from '../../apis/hooks/kbase'
import eventBus from '../../utils/event'
import { eventsMap } from '../../constants/events'
import { IOriginalDict } from '../../apis/generated/model'
import { useTranslation } from 'react-i18next'

export default function KbaseDetailCard(props: { onClose: () => void; kid: string }) {
  const { onClose, kid } = props
  const { queryKbaseRefs, loading } = useQueryKbaseRefs()
  const { t } = useTranslation()
  const [refData, setRefData] = React.useState({
    file: [],
    link: [],
  })

  const loadData = async () => {
    try {
      const resp = await queryKbaseRefs(kid)
      const data = {
        file: [],
        link: [],
      }
      resp.references?.forEach((ref: IOriginalDict) => {
        data[ref.imported_type].push(ref)
      })
      setRefData(data)
    } catch (error) {
      console.error(error)
    }
  }

  React.useEffect(() => {
    if (!kid) return
    loadData()

    const unbind = eventBus.on(eventsMap.RELOAD_KBASE_LIST, loadData)
    return unbind
  }, [kid])

  return (
    <div
      className="bg-white rounded"
      style={{
        borderRadius: 15,
        padding: '17px 20px',
        maxHeight: '80vh',
        overflowY: 'auto',
        width: 350,
      }}
    >
      <Spin spinning={loading}>
        <div>
          <div className="mb-4 flex">
            <div className="mr-auto">{t('imported_files')}</div>
            {onClose && <CloseCircleOutlined style={{ fontSize: 20, color: '#aaa' }} onClick={onClose} />}
          </div>
          <div>
            <List
              locale={{
                emptyText: t('empty_data'),
              }}
              itemLayout="horizontal"
              dataSource={refData.file}
              renderItem={(item: IOriginalDict) => (
                <List.Item
                  className="border mb-2 rounded-md"
                  style={{ padding: '10px 20px', backgroundColor: '#F6F7F7' }}
                >
                  <List.Item.Meta
                    title={
                      <Typography.Paragraph ellipsis={{ tooltip: item.title }} style={{ marginBottom: 0 }}>
                        {item.title}
                      </Typography.Paragraph>
                    }
                  />
                </List.Item>
              )}
            />
          </div>
        </div>

        <div>
          <div className="mb-4">{t('network_source')}</div>
          <div>
            <List
              locale={{
                emptyText: t('empty_data'),
              }}
              itemLayout="horizontal"
              dataSource={refData.link}
              renderItem={(item: IOriginalDict) => (
                <List.Item
                  className="border mb-2 rounded-md"
                  style={{ padding: '10px 20px', backgroundColor: '#F6F7F7' }}
                  actions={[
                    <a key="check" href={item.source} target="_blank">
                      {t('check')}
                    </a>,
                  ]}
                >
                  <List.Item.Meta
                    title={
                      <Typography.Paragraph ellipsis={{ tooltip: item.title }} style={{ marginBottom: 0 }}>
                        {item.title}
                      </Typography.Paragraph>
                    }
                  />
                </List.Item>
              )}
            />
          </div>
        </div>
      </Spin>
    </div>
  )
}
