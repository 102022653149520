import React from 'react'
import { useQueryTask } from '../apis/hooks/kbase'
import { CacheKey } from '../types'
import { ICreateProgressState } from '../types/kbase'
import eventBus from '../utils/event'
import { eventsMap } from '../constants/events'
import useLayoutContext from './useLayoutContext'

export default function useTaskProgress(task) {
  const [updatedTask, setUpdatedTask] = React.useState(task)
  const { queryTask, loading } = useQueryTask()
  const [progress, setProgress] = React.useState<string>(ICreateProgressState.requesting)
  const loopRef = React.useRef(false)
  const timerRef = React.useRef<any>()
  const { setImportingTask, importingTask } = useLayoutContext()

  const loopQuery = async (taskId: string) => {
    if (loopRef.current || importingTask?.status === ICreateProgressState.complete) return

    const resp = await queryTask(taskId)
    eventBus.emit(eventsMap.UPDATE_IMPORTING_TASK_DATA, resp)
    setUpdatedTask(resp)
    setImportingTask(resp)
    const { status } = resp
    setProgress(ICreateProgressState.complete)
    if (
      [ICreateProgressState.queued, ICreateProgressState.retrying, ICreateProgressState.processing].includes(status)
    ) {
      if (status === ICreateProgressState.processing) {
        setProgress(ICreateProgressState.processing)
      } else if (status === ICreateProgressState.retrying) {
        console.error({
          content: _t('createKBTaskFailed'),
          key: ICreateProgressState.retrying,
        })
        setProgress(ICreateProgressState.retrying)
      }
      timerRef.current = setTimeout(() => {
        loopQuery(taskId)
      }, 2e3)
    } else {
      if (status === ICreateProgressState.failed) {
        setProgress(ICreateProgressState.failed)
      }
    }
  }

  React.useEffect(() => {
    if (!task?.rid) return

    if (task?.rid) {
      loopQuery(task.rid)
    }
    const unbind = eventBus.on(eventsMap.DELETE_UPLOAD_TASK, () => {
      localStorage.removeItem(CacheKey.UPLOAD_INFO)
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    })
    const unbingStop = eventBus.on(eventsMap.STOP_TASK_LOOP, (target) => {
      if (task?.rid === target?.rid) {
        loopRef.current = true
        clearTimeout(timerRef.current)
      }
    })
    return () => {
      unbind()
      unbingStop()
    }
  }, [task?.rid])

  return { updatedTask, loading }
}
