import { LogtoConfig, UserScope } from '@logto/react'
import { API_URL } from './api'

export const logtoConfig: LogtoConfig = {
  endpoint: 'https://3z3aai.logto.app',
  appId: 'rumlr8xznvlsz86csyimw',
  scopes: [
    UserScope.Email,
    UserScope.Phone,
    UserScope.CustomData,
    UserScope.Identities,
    // UserScope.Organizations,
  ],
  resources: [API_URL], // Add API resources
}

