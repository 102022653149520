import ErrorIcon from '@assets/icons/error.svg'
import ImportingIcon from '@assets/icons/importing.svg'
import SuccessIcon from '@assets/icons/success.svg'
import { eventsMap } from '@constants/events'
import { Button, Space, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import eventBus from '../../../utils/event'
import styles from './index.module.css'
import useLogic, { ETaskStatus } from './useLogic'
import clsx from 'clsx'
import { reportEventTracking } from '@utils/tracking'
import useLayoutContext from '@hooks/useLayoutContext'

export default function UploadProgress(props) {
  const { task } = props
  const { setImportingTask, importingTask } = useLayoutContext()
  const navigate = useNavigate()
  const { statusInfo } = useLogic(props)

  const cancelHandler = () => {
    reportEventTracking('click_close_after_import')
    setImportingTask({ type: 'close' })
    eventBus.emit(eventsMap.UPLOAD_DONE, { data: null })
  }

  const navigateToKbase = () => {
    reportEventTracking('click_to_kbase_after_import')
    cancelHandler()
    navigate(`/kbase/chat/${task.kid}`)
  }
  const renderStatus = () => {
    switch (statusInfo?.status) {
      case ETaskStatus.LOADING:
        return (
          <>
            <div style={{ color: '#9F9F9F' }} className={clsx(styles.leftStatus, styles.rotatingSvg)}>
              <ImportingIcon />
              {statusInfo.text}
            </div>
            {/* <Space className={styles.rightAction}>
              <Button type="link" size="small" style={{ color: '#1E2336' }} onClick={cancelHandler}>
                {_t('cancel')}
              </Button>
            </Space> */}
          </>
        )
      case ETaskStatus.FAILED:
        return (
          <>
            <div style={{ color: '#F47147' }} className={styles.leftStatus}>
              <ErrorIcon />
              {statusInfo.text}
            </div>
            <Space className={styles.rightAction}>
              {/* <Button type="link" size="small" style={{ color: '#1E2336' }}>
                {_t('retry')}
              </Button> */}
              <Button type="link" size="small" style={{ color: '#1E2336' }} onClick={cancelHandler}>
                {_t('cancel')}
              </Button>
            </Space>
          </>
        )
      case ETaskStatus.SUCCESS:
        return (
          <>
            <div style={{ color: '#52c41a' }} className={styles.leftStatus}>
              <SuccessIcon />
              {statusInfo.text}
            </div>
            <Space className={styles.rightAction}>
              <Button type="link" size="small" onClick={navigateToKbase}>
                {_t('view')}
              </Button>
              <Button type="link" size="small" style={{ color: '#1E2336' }} onClick={cancelHandler}>
                {_t('close')}
              </Button>
            </Space>
          </>
        )
    }
  }

  return (
    <div className="w-full h-full">
      <Typography.Paragraph
        ellipsis={{ rows: 2, expandable: false, tooltip: task.title }}
        style={{ color: '#7E7C99', lineHeight: '17px' }}
      >
        {task.title || importingTask?.title || statusInfo?.task?.title}
      </Typography.Paragraph>
      <div className="flex w-full">{renderStatus()}</div>
    </div>
  )
}
