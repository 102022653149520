import { useQueryParam, StringParam } from 'use-query-params'

export default function useNewQuestion(config: { prefix?: string } = {}) {
  const setQuestion = useQueryParam('question', StringParam)[1]

  const onAskQuestion = (q) => {
    setQuestion(encodeURIComponent(`${config.prefix ?? ''}${q}`))
    const $wrap = document.getElementById('searchWrap')
    $wrap?.scrollIntoView()
  }

  return onAskQuestion
}
