import apis from '@apis/index'
import SearchInput from '@components/search-input'
import { Alert, Button } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { requestFn } from '../../utils/request'
import { Logout } from '../../layout/sidebar/logout'

export const InvitePage = () => {
  const [error, setError] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [isInWhitelist, setIsInWhitelist] = useState(false)
  const [code, setCode] = useState('')
  const navigate = useNavigate()

  const onVerify = async (code) => {
    try {
      const resp = await requestFn(apis.canSignupApiUserReferrerCodePost, code)
      const isValid = resp?.success

      if (isValid) {
        setCode(code)
        setIsValid(isValid)
      } else {
        setError(resp?.message || _t('inviteCodeError'))
      }
    } catch (error) {
      error.response?.json().then((resp) => {
        setError(resp?.data?.message || _t('inviteCodeError'))
      })
    }
  }

  useEffect(() => {
    const checkIfInWhitelist = async () => {
      const isInWhitelist = await requestFn(apis.isInWhitelistApiUserWhitelistInPost)
      if (isInWhitelist) {
        setIsInWhitelist(true)
      }
    }

    checkIfInWhitelist()
  }, [])

  const renderCodeInput = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <div style={{ width: '60vw', maxWidth: 720, margin: '80px auto 10px auto' }}>
          <SearchInput
            placeholder={_t('enterInviteCode')}
            onPressEnter={onVerify}
            onChange={() => {
              if (error) {
                setError(null)
              }
            }}
          />
        </div>
        {error && <Alert type="error" style={{ borderRadius: 10, color: '#ea220e' }} message={error} />}
        <Logout>
          <Button
            type="primary"
            shape="round"
            style={{ marginTop: 20, position: 'fixed', bottom: 20, left: 20 }}
            size="small"
          >
            {_t('logoutCurrentAccount')}
          </Button>
        </Logout>
      </div>
    )
  }

  const renderValidateResult = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <div
          style={{
            backgroundColor: '#000000',
            borderRadius: 100,
            width: 188,
            height: 68,
            lineHeight: '68px',
            color: '#F9F9F9',
            fontSize: 22,
            fontWeight: 700,
            marginTop: 80,
            marginBottom: 30,
            cursor: 'pointer',
          }}
          onClick={() => {
            navigate('/', { replace: true })
          }}
        >
          {_t('startUsing')}
        </div>
        {isInWhitelist && <div>{_t('userInvited')}</div>}
        {code && (
          <div>
            {_t('yourInviteCode')}
            {code}
          </div>
        )}
      </div>
    )
  }
  return (
    <div className="w-full h-screen flex justify-center flex-col items-center" style={{ marginTop: -100 }}>
      <div style={{ fontSize: 82 }}>Hello Hika</div>
      <div style={{ fontSize: 32, fontWeight: 600 }}>{_t('hikaSlogan')}</div>
      {isValid || isInWhitelist ? renderValidateResult() : renderCodeInput()}
    </div>
  )
}
