import KbaseImport from '@components/import-button/kbase-import'
import KbaseCardWrap from './kbase-card-wrap'

export default function KbaseCreationCard(props) {
  return (
    <KbaseCardWrap>
      <div className="flex flex-col" style={{ color: '#1E2336' }}>
        <div style={{ marginBottom: 25 }}>{_t('createKbase')}</div>
        <KbaseImport />
      </div>
    </KbaseCardWrap>
  )
}
