import { userInfoCache } from './cache'

export function reportEventTracking(eventName, params = {}) {
  try {
    const userInfo = userInfoCache.get()
    if (
      [
        'dcfc9cb49b87419485648779fd954080',
        '22efea0788e34bbd8d18bf2c4ee59604',
        'b9b975da5c72418f99efca166e150379',
        'd68bc3209441405f8e05c932b2ea2559',
      ].includes(userInfo?.info?.uid)
    )
      return
    ;(window as any).clarity('event', eventName, {
      ...params,
      url: location.href,
    })
  } catch {
    //do nth
  }
}
