import { useCallback, useState } from 'react'
import { useAuthApi } from '../../hooks/useFetch'
import { API_URL, S3_URL } from '../../constants/api'

export function useUploadImage() {
  const { authFetch, loading } = useAuthApi()
  const [imageUrl, setImageUrl] = useState<string>()

  const uploadImage = useCallback(
    async ({ onError, onSuccess, file }) => {
      const formData = new FormData()
      formData.append('file', file)
      const url = new URL(API_URL + '/upload/images')
      setImageUrl('')
      try {
        const [filepath, error] = await authFetch(url, {
          method: 'POST',
          body: formData,
        })
        if (error) {
          console.error(error)
          onError({ status: status, message: filepath })
          return
        }

        const data = {
          filepath,
          imageUrl: `${S3_URL}${filepath}`,
        }
        setImageUrl(data.imageUrl)
        onSuccess(data, file)
        return data.imageUrl
      } catch (err) {
        console.error(err)
        throw err
      }
    },
    [authFetch]
  )

  return { uploadImage, loading, imageUrl }
}
