import { Form, MenuProps } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useDeleteKbase, useUpdateKbase } from '../../apis/hooks/kbase'
import { eventsMap } from '../../constants/events'
import eventBus from '../../utils/event'
import { IKbaseDict } from '../../apis/generated/model'
import { useTranslation } from 'react-i18next'

enum EChangeType {
  update = 'update',
  delete = 'delete',
  none = 'none',
}
export default function useLogic(kbase: IKbaseDict) {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const { deleteKbase, loading: deleting } = useDeleteKbase()
  const { updateKbase, loading: updating } = useUpdateKbase()
  const { t } = useTranslation()
  const items: MenuProps['items'] = [
    {
      key: EChangeType.update,
      label: t('setting'),
    },
    {
      type: 'divider',
    },
    {
      key: EChangeType.delete,
      danger: true,
      label: t('delete'),
    },
  ]

  const saveKbase = async () => {
    const values = await form.validateFields()

    const { kid, ...rest } = kbase
    await updateKbase({
      kid,
      profile_image_url: values.profile_image_url,
      kbase_config: {
        ...rest,
        ...values,
      },
    })

    eventBus.emit(eventsMap.RELOAD_KBASE_LIST)
  }

  return {
    navigate,
    deleteKbase,
    saveKbase,
    items,
    deleting,
    updating,
    form,
  }
}
