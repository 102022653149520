import { useLogto } from '@logto/react'

export default function useSignIn() {
  const { signIn } = useLogto()

  const handleSignIn = () => {
    localStorage.removeItem('token')
    const signInUrl = `${location.origin}/callback`
    signIn(signInUrl)
  }

  return handleSignIn
}
