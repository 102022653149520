import React from 'react'
import { Button, Result } from 'antd'
import { Link } from 'react-router-dom'
import { MehOutlined } from '@ant-design/icons'

const NotFound: React.FC = () => (
  <Result
    title="404"
    icon={<MehOutlined />}
    subTitle="Sorry, the page you visited does not exist."
    extra={
      <Link to="/">
        <Button type="primary" shape="round">
          Back Home
        </Button>
      </Link>
    }
  />
)

export default NotFound
