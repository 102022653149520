const routes = {
  HOME: '/',
  LOGIN: '/login',
  SETTINGS: '/settings',
  SEARCH: '/search/:topic_id',
  TEMP_SEARCH: '/search',
  SEARCH_COMMUNITY: '/search-community',
  CHAT: '/chat',
  MY_KBASE: '/my-kbase',
  KBASE_DETAIL: '/kbase/:id',
  CALLBACK: '/callback',
  INVITE_PAGE: '/invite',
}
export default routes
