import useTaskProgress from '@hooks/useTaskProgress'
import { ICreateProgressState } from '../../../types/kbase'
import eventBus from '@utils/event'
import { eventsMap } from '@constants/events'

export enum ETaskStatus {
  FAILED = -1,
  LOADING = 0,
  SUCCESS = 1,
}
export default function useLogic(props) {
  const { task } = props
  const { updatedTask } = useTaskProgress(task)

  const getStatusInfo = () => {
    switch (updatedTask?.status) {
      case ICreateProgressState.pending:
      case ICreateProgressState.queued:
      case ICreateProgressState.processing:
        return {
          status: ETaskStatus.LOADING,
          text: _t('importing'),
          task: updatedTask,
        }
      case ICreateProgressState.complete:
        eventBus.emit(eventsMap.CREATE_KBASE_SUCCESS, updatedTask)
        return {
          status: ETaskStatus.SUCCESS,
          text: _t('importSuccess'),
          task: updatedTask,
        }

      case ICreateProgressState.failed:
        return {
          status: ETaskStatus.FAILED,
          text: _t('importFailed'),
          task: updatedTask,
        }
      default:
        return {
          task: updatedTask,
        }
    }
  }
  return {
    statusInfo: getStatusInfo(),
  }
}
