import { Spin } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { validateAdmin } from '../../../apis/hooks/user'

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate()
  const [validating, setValidating] = React.useState(true)
  const [isAuth, setIsAuth] = React.useState(false)

  React.useEffect(() => {
    validateAdmin()
      .then((pass) => {
        setIsAuth(pass)
        if (!pass) {
          navigate('/admin/login')
          return
        }
        const isLoginPage = location.pathname === '/admin/login'
        if (isLoginPage) {
          navigate('/admin/dashboard')
        } else {
          return children
        }
      })
      .finally(() => {
        setValidating(false)
      })
  }, [])

  if (validating) return <Spin />

  if (isAuth) return children
  return null
}

export default ProtectedRoute
