import React, { useState, useEffect } from 'react'
import { Table, Button, Modal, Form, Input } from 'antd'
import ky from 'ky'

const WhiteListUsersPage = () => {
  const [users, setUsers] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    ky.get('/api/users').then((response) => {
      setUsers(response.data)
    })
  }, [])

  const handleDelete = (userId) => {
    ky.delete(`/api/users/${userId}`).then(() => {
      setUsers(users.filter((user) => user.id !== userId))
    })
  }

  const handleAddUser = (values) => {
    ky.post('/api/users', values).then((response) => {
      setUsers([...users, response.data])
      setIsModalVisible(false)
    })
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Button danger onClick={() => handleDelete(record.id)}>
          Delete
        </Button>
      ),
    },
  ]

  return (
    <>
      <Button type="primary" onClick={() => setIsModalVisible(true)} className="mb-4">
        Add User
      </Button>
      <Table columns={columns} dataSource={users} rowKey="id" />

      <Modal title="Add User" visible={isModalVisible} onCancel={() => setIsModalVisible(false)} footer={null}>
        <Form onFinish={handleAddUser}>
          <Form.Item name="name" rules={[{ required: true, message: 'Please input the name!' }]}>
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item name="email" rules={[{ required: true, message: 'Please input the email!' }]}>
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default WhiteListUsersPage
