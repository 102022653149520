import { IS_NEW_SEARCH } from '@constants/search'

export function setNewSearchTag() {
  sessionStorage.setItem(IS_NEW_SEARCH, '1')
}

export function getNewSearchTag() {
  const tag = sessionStorage.getItem(IS_NEW_SEARCH)
  removeNewSearchTag()
  return tag
}

export function removeNewSearchTag() {
  sessionStorage.removeItem(IS_NEW_SEARCH)
}
