import { useGlobalQueryKbase } from '../apis/hooks/kbase'
import { useState } from 'react'
import { getLang, readStream } from '../utils/request'
import { IStreamChunk } from '../types/kbase'
import { IQueryRequest } from '@apis/generated/model'

export default function useKbaseGlobalQuery() {
  const { queryKbase, loading } = useGlobalQueryKbase()
  const [answer, setAnswer] = useState({
    loading: false,
    token: -1,
    data: {},
  })

  const onKbaseGlobalQuery = async (query: Partial<IQueryRequest>, kbase, idx) => {
    let streamAns: IStreamChunk = {
      loading: true,
      token: idx,
      data: { kbase },
    }

    const updateAnswer = (fn) => {
      streamAns = fn(streamAns)
      setAnswer({ ...streamAns })
    }

    return queryKbase({
      ...query,
      kid: kbase.kid,
      stream: true,
      language: kbase.language || getLang(),
      query_style: 'note',
      list_of_tags: [],
    }).then((res) => {
      return readStream(res, updateAnswer)
    })
  }

  return { answer, setAnswer, loading, onKbaseGlobalQuery }
}
