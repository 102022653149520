import GrayLinkIcon from '@assets/icons/gray-link.svg'
import LinkIcon from '@assets/icons/link.svg'
import { Button, message, Space } from 'antd'
import styles from './index.module.scss'
import clsx from 'clsx'
import useLayoutContext from '@hooks/useLayoutContext'
import { useTranslation } from 'react-i18next'

interface IProps {
  isUploading?: boolean
  isCreating?: boolean
  onClick: () => void
  active?: boolean
  disabled?: boolean
}
export default function LinkImportButton(props: IProps) {
  const { importingTask } = useLayoutContext()
  const { isUploading, isCreating, onClick, active, disabled } = props
  const { t } = useTranslation()

  const isDisabled = isUploading || !!importingTask?._isProcessing || disabled
  return (
    <Button
      className={clsx(active && styles.active, styles.linkBtn)}
      type="link"
      loading={isCreating}
      onClick={(event) => {
        if (isDisabled) {
          if (importingTask?._isProcessing) {
            message.info(t('import_validate_msg'))
          }
          event.stopPropagation()
          return
        }
        onClick()
      }}
      disabled={disabled}
    >
      <Space size="small">
        <div className="flex" style={{ gap: 6 }}>
          {isDisabled ? <GrayLinkIcon /> : <LinkIcon />}
          {t('import_link')}
        </div>
      </Space>
    </Button>
  )
}
