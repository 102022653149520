import { Form } from 'antd'
import React, { useContext, useState } from 'react'
import { AuthContext } from '../../layout/components/auth-provider'

const YEAR = new Date().getFullYear()

const useLogic = () => {
  const { user, updateUserInfo } = useContext(AuthContext)
  const [form] = Form.useForm()
  const [open, setOpen] = useState(false)
  const [inputName, setInputName] = useState(user?.name)

  React.useEffect(() => {
    setInputName(user?.info.name || user?.name)
  }, [user])
  const showModal = () => {
    setOpen(true)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const updateUserHandler = async (info) => {
    const userInfo = user?.info || {}

    const data = {
      ...userInfo,
      ...info,
    }
    delete data.query_times
    await updateUserInfo(data)
  }

  return {
    user,
    form,
    updateUserHandler,
    inputName,
    setInputName,
    showModal,

    open,
    handleCancel,
    YEAR,
  }
}

export default useLogic
