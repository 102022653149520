import { useLogto } from '@logto/react'
import { Button, Dropdown, MenuProps, Space, Typography } from 'antd'
import React, { useContext, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import routes from '../../constants/routes'
import { AuthContext } from '../components/auth-provider'
import { Login } from './login'
import { Logout } from './logout'
import SearchIcon from '@assets/icons/search.svg'
import CommunityIcon from '@assets/icons/community.svg'
import MyKbaseIcon from '@assets/icons/my-kbase.svg'
import FavoredIcon from '@assets/icons/favored.svg'
import apis from '../../apis'
import { handleResponse } from '../../utils/request'
import eventBus from '../../utils/event'
import { ITopicHistoryDict } from '../../apis/generated/model'
import { eventsMap } from '@constants/events'
import useTopicId from '@hooks/useTopicId'
import { reportEventTracking } from '@utils/tracking'
import useLayoutContext from '@hooks/useLayoutContext'
import FavButton from '@components/fav-button'
import clsx from 'clsx'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'

function MenuTitle(props) {
  return (
    <Space style={{ color: '#7E7C99' }} align="center" className={props.className}>
      {props.src}
      {props.title}
      {props.after?.()}
    </Space>
  )
}

export default function useLogic() {
  const { t } = useTranslation()
  const { isAuthenticated } = useLogto()
  const location = useLocation()
  const { user } = useContext(AuthContext)
  const [topics, setTopics] = React.useState([])
  const [total, setTotal] = React.useState(0)
  const [tempTopics, setTempTopics] = React.useState<ITopicHistoryDict[]>([])
  const [persistTopics, setPersisTopics] = React.useState<ITopicHistoryDict[]>([])
  const topic_id = useTopicId()
  const [visible, setVisible] = React.useState(false)
  const { importingTask: uploadingTask } = useLayoutContext()
  const loading = useRef(false)

  const [visibleUploadList, setVisibleUploadList] = React.useState(false)

  React.useEffect(() => {
    const unbindVisible = eventBus.on(eventsMap.IMPORT_HISTORY_VISIBILITY_CHANGE, setVisibleUploadList)

    return () => {
      unbindVisible()
    }
  }, [])

  React.useEffect(() => {
    if (topic_id) {
      const target = topics.find((t) => t.topic_id === topic_id)
      target && eventBus.emit(eventsMap.SET_CURRENT_TOPIC, target)
    } else {
      eventBus.emit(eventsMap.SET_CURRENT_TOPIC, null)
    }
  }, [topic_id, topics])

  const items: MenuProps['items'] = React.useMemo(() => {
    return [
      isAuthenticated && {
        key: 'setting',
        label: (
          <a rel="noopener noreferrer" href="/user/setting">
            Setting
          </a>
        ),
      },
      isAuthenticated && {
        key: 'logout',
        label: <Logout />,
      },
      !isAuthenticated && {
        key: 'login',
        label: <Login />,
      },
    ].filter(Boolean)
  }, [isAuthenticated])

  const fetchTopics = async () => {
    if (loading.current) return
    loading.current = true
    apis
      .listSearchTopicsApiHistorySearchTopicsPost({
        page: 1,
        limit: 500,
      })
      .then(handleResponse)
      .then((res) => {
        const topics = res.messages ?? []
        setPersisTopics(topics.filter((t) => t.is_persist))
        setTempTopics(topics.filter((t) => !t.is_persist))
        setTopics(topics)
        setTotal(res.total ?? topics.length)
      })
      .finally(() => {
        loading.current = false
      })
  }

  useEffect(() => {
    fetchTopics()
    return eventBus.on(eventsMap.REFRESH_TOPIC_LIST, fetchTopics)
  }, [])

  const menus = React.useMemo(() => {
    return [
      {
        key: routes.HOME,
        label: <MenuTitle src={<SearchIcon />} title={t('start_search')} />,
      },
      false && {
        key: routes.SEARCH_COMMUNITY,
        label: <MenuTitle src={<CommunityIcon />} title={_t('searchCommunity')} />,
      },
      false && {
        key: routes.MY_KBASE,
        label: (
          <div className="flex items-center">
            <MenuTitle src={<MyKbaseIcon />} title={t('my_kbase')} />
            <div
              style={{
                marginLeft: 'auto',
                color: '#7E7C99',
                fontWeight: 'normal',
              }}
              onClick={(event) => {
                event.stopPropagation()
                reportEventTracking('click_my_kbase_import_history')
                setVisible((visible) => {
                  eventBus.emit(eventsMap.IMPORT_HISTORY_VISIBILITY_CHANGE, !visible)
                  return !visible
                })
              }}
            >
              {visible ? t('open') : t('import_history')}
            </div>
          </div>
        ),
      },
      {
        // key: routes.SEARCH,
        label: (
          <MenuTitle
            src={<FavoredIcon />}
            title={<div id="my-fav-search">{t('favorite_search')}</div>}
            className={styles.favedSearch}
          />
        ),
        children: persistTopics.map((t) => {
          return {
            key: `${routes.SEARCH.replace(':topic_id', t.topic_id)}`,
            label: (
              <div className={clsx('flex', styles.topicItem)}>
                <Typography.Paragraph ellipsis className="flex-1" style={{ lineHeight: '40px' }}>
                  {t.title}
                </Typography.Paragraph>

                <FavButton
                  topic={t}
                  animOption={{ speed: 50 }}
                  style={{ marginLeft: 'auto' }}
                  trackingName="click_fav_topic_button_on_fav_menu"
                />
              </div>
            ),
          }
        }),
      },
    ].filter(Boolean)
  }, [persistTopics, visible])

  return {
    uploadingTask,
    visibleUploadList,
    topics,
    total,
    isAuthenticated,
    tempTopics,
    persistTopics,
    user,
    location,
    items,
    menus,
  }
}
