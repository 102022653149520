import apis from '@apis/index'
import type { IOutputProps } from './types'
import { useEffect, useState } from 'react'
import MindmapView from './mindmap'
import { useEventStreamFetch } from '@hooks/useEventStreamFetch'
import { Tag } from 'antd'
import styles from './index.module.scss'
import { Button } from 'antd'
import useNewQuestion from '../../hooks/useNewQuestion'
import { reportEventTracking } from '@utils/tracking'
import { isFromHistory } from './utils'

export default function KeywordsView(props: IOutputProps) {
  const { response_id } = props
  const [keywords, setKeywords] = useState(props.keywords || [])
  const { request, data, loading } = useEventStreamFetch(apis.transformAnswerToKeywordsApiAnswerTransformKeywordsPost)
  const isHistory = isFromHistory(props)
  const hasCacheMindmap = 'mindmap' in props

  const [mindmapVisibile, setMindmapVisible] = useState(isHistory ? hasCacheMindmap : true)

  const fetchKeywords = async (response_id: string) => {
    try {
      await request({ response_id })
    } catch (error) {
      console.warn(error)
    }
  }

  useEffect(() => {
    if (isHistory || hasCacheMindmap) {
      return
    }

    if (response_id && !loading) {
      fetchKeywords(response_id)
    }
  }, [response_id])

  useEffect(() => {
    if (data?.done && data?.data?.chunk) {
      setKeywords(data.data?.chunk.split(',').map((k) => k.trim()))
    }
  }, [data?.done])

  if (!keywords?.length) return null

  return (
    <div className="mb-6">
      <div className="mb-4 px-3">
        <span style={{ color: '#1E2336', fontSize: 16 }} className="mr-2 font-semibold">
          {_t('clues')}
        </span>
        {!mindmapVisibile && (
          <span className={styles.viewDiagramBtn} onClick={setMindmapVisible.bind(null, true)}>
            {_t('generateRelationshipDiagram')}
          </span>
        )}
      </div>
      <Keywords keywords={keywords} />
      {mindmapVisibile && <MindmapView {...props} keywords={keywords} />}
    </div>
  )
}

function Keywords(props) {
  const { keywords } = props
  const askQuestion = useNewQuestion({ prefix: _t('about') })

  return (
    <div>
      {keywords?.map((k) => {
        return (
          <Button
            type="link"
            key={k}
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
              reportEventTracking('search_answer_keyword.click')
              askQuestion(k)
            }}
            className="p-0 mb-5"
          >
            <Tag color="#F3F3F3" bordered={false} className={styles.keyword}>
              {k}
            </Tag>
          </Button>
        )
      })}
    </div>
  )
}
