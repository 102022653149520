import { Avatar, Form, Input, Select } from 'antd'
import UploadImage from '../upload-img'
import React from 'react'
import { generateColorsFromFirstChar } from '@utils/index'

const formItemLayout = {
  layout: 'vertical',
}

export default function KbaseSetting(props) {
  const { data, form } = props
  const [imageUrl, setImageUrl] = React.useState(data.profile_image_url)

  if (!data) return null

  return (
    <Form form={form} name="kbase-form" {...formItemLayout} initialValues={data} style={{ maxWidth: 600 }}>
      <Form.Item name="profile_image_url" valuePropName="profile_image_url" style={{ textAlign: 'center' }}>
        <UploadImage
          onChange={(img) => {
            setImageUrl(img)
            form.setFieldValue('profile_image_url', img)
          }}
        >
          <Avatar
            src={imageUrl}
            size="large"
            style={{
              width: '100%',
              fontSize: 50,
              height: '100%',
              ...(!imageUrl && generateColorsFromFirstChar(data?.nick_name)),
            }}
          >
            {data?.nick_name?.[0]}
          </Avatar>
        </UploadImage>
      </Form.Item>
      <Form.Item name="nick_name" label={_t('kbaseName')} hasFeedback rules={[{ required: true, max: 100 }]}>
        <Input />
      </Form.Item>
      <Form.Item name="description" label={_t('kbaseIntro')} hasFeedback rules={[{ max: 100 }]}>
        <Input.TextArea rows={3} autoSize={false} />
      </Form.Item>
    </Form>
  )
}
