import * as G6 from '@antv/g6'
import mermaid from 'mermaid'
import { useEffect, useRef } from 'react'

function mermaidToG6(mermaidData) {
  const lines = mermaidData.split('\n')
  const nodes = []
  const edges = []

  lines.forEach(function (line) {
    let match = line.match(/(\w+)\[(.*?)\]/)
    if (match) {
      nodes.push({ id: match[1], label: match[2] })
    }

    match = line.match(/(\w+)\s*-->\s*\|\s*?(.*?)\s*\|\s*?(\w+)/)
    if (match) {
      edges.push({ source: match[1], target: match[3], label: match[2] })
    }
  })

  return { nodes: nodes, edges: edges }
}
function parseMermaid(mermaidSyntax) {
  const el = document.createElement('div') // Create a div element
  el.id = 'graphDiv' // Set the id attribute
  document.body.appendChild(el) // Append it to the body
  // Initialize Mermaid with v11 settings
  mermaid.initialize({ startOnLoad: false })

  // G6-compatible graph data
  const graphData = { nodes: [], edges: [] }
  try {
    // Use Mermaid's parse() to validate syntax
    mermaid.parse(mermaidSyntax)

    // Generate an SVG to access Mermaid's intermediate data
    mermaid.render('graphDiv', mermaidSyntax).then((result) => {
      // Process the SVG to create G6 nodes/edges
      const parser = new DOMParser()
      debugger
      const svgDoc = parser.parseFromString(result.svg, 'image/svg+xml')
      const svgNodes = svgDoc.querySelectorAll('.node')
      const svgEdges = svgDoc.querySelectorAll('.edgeLabels')
      debugger
      // Extract nodes
      svgNodes.forEach((node) => {
        const nodeId = node.getAttribute('id')
        const nodeLabel = node.querySelector('.nodeLabel').textContent || ''
        graphData.nodes.push({
          id: nodeId,
          label: nodeLabel,
        })
      })

      // Extract edges
      svgEdges.forEach((edge) => {
        const edgeId = edge.getAttribute('id')
        const source = edge.getAttribute('data-source')
        const target = edge.getAttribute('data-target')
        const edgeLabel = edge.querySelector('text')?.textContent || ''

        if (source && target) {
          graphData.edges.push({
            id: edgeId,
            source,
            target,
            label: edgeLabel,
          })
        }
      })
    })
  } catch (error) {
    console.error('Error parsing Mermaid syntax:', error)
  }
  console.log('graphData', graphData)
  return graphData
}

// Example function to convert Mermaid syntax to G6-compatible format
async function convertMermaidToG6(mermaidData) {
  // Placeholder for your parsed data
  const parsedData = mermaidToG6(mermaidData) // Implement a parsing function or use a library

  return parsedData
  const nodes = parsedData.nodes.map((node) => ({
    id: node.id,
    label: node.label,
    // Additional G6 styling options here
  }))

  const edges = parsedData.edges.map((edge) => ({
    source: edge.source,
    target: edge.target,
    label: edge.label || '',
    // Additional G6 edge styling options here
  }))

  return { nodes, edges }
}

export function BeautifiedMermaid(props) {
  const ref = useRef()
  useEffect(() => {
    if (!props.input) return

    // Convert Mermaid data and load into G6
    convertMermaidToG6(props.input).then((g6Data) => {
      //   const graph = new G6.Graph({
      //     container: ref.current,
      //     width: 800,
      //     height: 600,
      //     data: g6Data,
      //     defaultNode: {
      //       type: 'circle',
      //       style: {
      //         fill: '#9ec9ff',
      //         stroke: '#5b8ff9',
      //       },
      //       size: [100],
      //       labelCfg: {
      //         style: {
      //           fill: '#000',
      //           fontSize: 14,
      //         },
      //       },
      //     },
      //     defaultEdge: {
      //       type: 'line',
      //       style: {
      //         stroke: '#bfbfbf',
      //       },
      //       labelCfg: {
      //         autoRotate: true,
      //       },
      //     },
      //     layout: {
      //       type: 'dagre',
      //       direction: 'TB',
      //     },
      //   })

      // //   graph.data(g6Data)
      //   graph.render()
      //   return
      // Initialize G6 Graph
      const graph = new G6.Graph({
        container: ref.current,
        autoFit: 'view',
        data: g6Data,
        node: {
          style: {
            labelText: (d) => d.label,
            // fill: '#FFFFFF',
            labelPlacement: 'right',
            labelBackground: true,
            ports: [{ placement: 'right' }, { placement: 'left' }],
          },
          animation: {
            enter: false,
          },
        },
        edge: {
          type: 'polyline',
          style: {
            label: true,
            labelBackground: true,
            router: {
              type: 'orth',
            },
            // stroke: '#CCCCCC',
            labelText: (d) => {
              return d.label
            },
          },
          animation: {
            enter: false,
          },
        },
        layout: {
          type: 'mindmap',
          direction: 'LR',
          getHeight: () => 32,
          getWidth: () => 32,
          getVGap: () => 4,
          getHGap: () => 100,
        },
        behaviors: ['collapse-expand', 'drag-canvas', 'zoom-canvas'],
      })

      // Load the data and render
      graph.render()
    })
  }, [props.input])
  return <div ref={ref} style={{ height: 400, backgroundColor: 'white', padding: 10 }}></div>
}

export const MermaidRenderer = ({ chart }) => {
  // return <BeautifiedMermaid input={chart} />
  const containerRef = useRef(null)

  useEffect(() => {
    if (containerRef.current) {
      mermaid.contentLoaded()
      containerRef.current.parentNode.style.backgroundColor = 'transparent'
      containerRef.current.parentNode.style.padding = '0'
    }
  }, [chart])

  return (
    <div
      className="mermaid  not-prose"
      style={{ backgroundColor: '#F7F6F3', borderRadius: 14, padding: 15 }}
      ref={containerRef}
    >
      {chart}
    </div>
  )
}
