import React from 'react'
import { useQueryKbaseList } from '../../apis/hooks/kbase'
import { eventsMap } from '../../constants/events'
import eventBus from '../../utils/event'
import { IKbaseDict } from '../../apis/generated/model'

export enum SORT_TYPE {
  recent = 'recent',
  date = 'created_at',
  all = 'popularity',
}
const MAX_NUM = 3

function useKbases(sortType: SORT_TYPE) {
  const { queryKbaseList, loading } = useQueryKbaseList()
  const [kbases, setKbases] = React.useState<IKbaseDict[]>([])

  const refresh = async () => {
    queryKbaseList({ sort_by: sortType }).then((data) => {
      const kbases = data?.kbases ?? []
      if (kbases?.length < MAX_NUM) {
        kbases.push({})
      }
      setKbases(kbases)
    })
  }
  React.useEffect(() => {
    refresh()
  }, [sortType])

  return { kbases, setKbases, refresh, loading }
}

export default function useLogic() {
  const [activeSortType, setActiveSortType] = React.useState(SORT_TYPE.all)
  const { kbases, refresh, loading } = useKbases(activeSortType)

  React.useEffect(() => {
    const unbind = eventBus.on(eventsMap.RELOAD_KBASE_LIST, refresh)
    const unbindRefresh = eventBus.on(eventsMap.UPLOAD_DONE, refresh)
    return () => {
      unbindRefresh()
      unbind()
    }
  }, [])

  const toggleSortType = (type: SORT_TYPE) => {
    if (activeSortType === type) {
      setActiveSortType(SORT_TYPE.all)
    } else {
      setActiveSortType(type)
    }
  }

  return {
    activeSortType,
    toggleSortType,
    loading,
    kbases,
  }
}
