import { ProChat } from '@ant-design/pro-chat'
import { Avatar, Button, Card, Divider, Result, Space, Spin, Typography } from 'antd'
import ImportButtons from '../../components/import-button'
import KbaseDetailCard from '../../components/kbase-detail'
import styles from './index.module.scss'
import useLogic from './useLogic'
import { AnswerOutputRenderer } from '../search/components/answer-renderer'
import SearchInput from '@components/search-input'
import KbaseAvatar from '@components/kbase-avatar'
import { reportEventTracking } from '@utils/tracking'

export default function KbaseChat() {
  const {
    user,
    kid,
    kbase,
    isVisible,
    setIsVisible,
    queryAnswer,
    messages,
    setMessages,

    queryMessagesHistory,
    requestPreviousPage,

    quota,

    loading,
    proChatRef,
  } = useLogic()

  const renderErrorMessages = (errorResponse) => {
    return (
      <Card>
        <Result
          status="error"
          title="Something Error"
          subTitle={errorResponse.message}
          extra={[
            <Button type="primary" key="console">
              {_t('retry')}
            </Button>,
            // <Button key="buy">Buy Token</Button>,
          ]}
        />
      </Card>
    )
  }

  if (!kbase) return <Spin />

  const disabledImport = quota?.left === 0
  return (
    <div className={styles.wrapper}>
      <div className="flex items-center">
        <Typography.Paragraph className="text-lg" ellipsis style={{ marginBottom: 0, flex: 1, marginRight: 6 }}>
          {kbase.nick_name}
        </Typography.Paragraph>
        {/* <Typography.Paragraph
          ellipsis
          style={{ marginBottom: 0, color: '#BCBCBC', flex: 1, marginRight: 6, marginLeft: 6 }}
        >
          {kbase.description}
        </Typography.Paragraph> */}
        <div className="ml-auto">
          <Space>
            <Button
              shape="round"
              onClick={() => {
                reportEventTracking('kbase_chat_click_view_kbase')
                setIsVisible(true)
              }}
            >
              {_t('viewKbase')}
            </Button>
            <ImportButtons
              disabled={disabledImport}
              before={
                quota && (
                  <div style={{ color: '#9F9F9F', fontSize: 12, marginBottom: 5 }}>{_t('importTimesLeft', quota)}</div>
                )
              }
            >
              <Button shape="round">{_t('addFileOrLink')}</Button>
            </ImportButtons>
          </Space>
        </div>
      </div>
      <Divider style={{ margin: '10px 0' }} />
      <div className="flex flex-1">
        <div style={{ width: '60%', margin: '0 auto' }}>
          <ProChat
            chatRef={proChatRef}
            key={kbase?.kid}
            chats={messages}
            onChatsChange={(chats) => {
              setMessages(chats)
            }}
            loading={loading}
            helloMessage={_t('kbaseSearchPrompt')}
            style={{ height: '87vh', overflow: 'auto' }}
            userMeta={{
              avatar: user?.picture ?? '',
              title: user?.name ?? '',
            }}
            onScroll={(event) => {
              const threhold = 100
              const scrollTop = (event.target as HTMLElement)?.scrollTop
              if (scrollTop < threhold) {
                // requestPreviousPage()
                //todo, request previous page
              }
            }}
            assistantMeta={{
              avatar: kbase?.profile_image_url, //<KbaseAvatar kbase={kbase} />,
              title: 'Hika Bot',
              backgroundColor: '#67dedd',
            }}
            chatItemRenderConfig={{
              titleRender: (item, dom) => {
                return dom
              },
              actionsRender: false,
              contentRender: (item, defaultDom) => {
                if (item?.originData?.role === 'assistant') {
                  return (
                    <div style={{ marginRight: 70 }}>
                      <AnswerOutputRenderer answer={item.originData.content} />
                    </div>
                  )
                }
                return defaultDom
              },
            }}
            inputAreaRender={(_defaultDom, onMessageSend) => {
              return (
                <div className="flex justify-center" style={{ paddingTop: 15 }}>
                  <SearchInput placeholder={_t('continueAsking')} onPressEnter={onMessageSend} />
                </div>
              )
            }}
            renderErrorMessages={renderErrorMessages}
            request={queryAnswer}
          />
        </div>
        {isVisible && (
          <div
            style={{
              padding: 10,
              backgroundColor: '#F2F2F1',
              borderRadius: 24,
              height: 'fit-content',
            }}
            className="border rounded"
          >
            <KbaseDetailCard kid={kid} onClose={() => setIsVisible(false)} />
          </div>
        )}
      </div>
    </div>
  )
}
