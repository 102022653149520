import { Divider, Popover } from 'antd'
import React, { useEffect } from 'react'
import { IMPORT_TYPE } from '../../types/kbase'
import AnimWrapper from '../anim-wrapper'
import KbaseFileCreation from '../kbase-file-creation'
import FileImportButton from './file-import-button'
import KbaseLinkImport, { CANCEL_LINK_IMPORT } from './kbase-link-import'
import LinkImportButton from './link-import-button'
import eventBus from '@utils/event'
import styles from './index.module.scss'

export default function ImportButtons(props) {
  const { disabled } = props
  const [importType, setImportType] = React.useState(IMPORT_TYPE.NIL)
  const animRef = React.useRef<any>()
  const [isUploading] = React.useState(false)

  const reset = () => setImportType(IMPORT_TYPE.NIL)

  useEffect(() => {
    return eventBus.on(CANCEL_LINK_IMPORT, reset)
  }, [])

  const renderActions = () => {
    return (
      <div className={disabled && styles.disabledUpload}>
        {importType !== IMPORT_TYPE.LINK && (
          <>
            {props.before}
            <div className="flex flex-col">
              <KbaseFileCreation
                onUploadStatusChange={(...args) => {
                  const phase = args[0]
                  if (phase === 'start') {
                    animRef.current?.run()
                  }
                }}
              >
                <FileImportButton
                  disabled={disabled}
                  onClick={(event) => {
                    if (disabled) {
                      event.stopPropagation()
                      return
                    }
                    setImportType(IMPORT_TYPE.FILE)
                  }}
                />
              </KbaseFileCreation>
              <Divider style={{ margin: '8px 0' }} />
              <LinkImportButton
                disabled={disabled}
                onClick={() => {
                  if (disabled) return
                  setImportType(IMPORT_TYPE.LINK)
                }}
                isUploading={isUploading}
              />
            </div>
          </>
        )}
        {importType === IMPORT_TYPE.LINK && (
          <div style={{ width: 286 }}>
            <KbaseLinkImport onBeforeSubmit={() => animRef.current?.run()} />
          </div>
        )}
      </div>
    )
  }
  return (
    <AnimWrapper lazy ref={animRef}>
      <Popover content={renderActions()} overlayInnerStyle={{ borderRadius: 10 }} placement="bottom">
        {props.children}
      </Popover>
    </AnimWrapper>
  )
}
