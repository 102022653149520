import { useLogto } from '@logto/react'
import { API_URL } from '../constants/api'
import { useNavigate } from 'react-router-dom'
import { useCallback, useState } from 'react'
import { message } from 'antd'
import i18next from 'i18next'
import { request } from '../utils/request'

export function useGetResponseHandler() {
  const navigate = useNavigate()

  const resolveResponse = useCallback(
    async (response: Response): Promise<[any, Error | null]> => {
      if (!response) {
        message.error(i18next.t('errorsMap.401'))
        // navigate("/home");
        throw new Error('Unauthorized')
      }
      const status = response.status

      if (status === 500) {
        message.error(i18next.t('errorsMap.500'))
        throw new Error('Server error')
      } else if (status === 401) {
        message.error(i18next.t('errorsMap.401'))
        navigate('/login')
        throw new Error('Unauthorized')
      } else if (status !== 200) {
        //TODO: exception handling
      }

      const data = await response.json()

      if (!response.ok) {
        const error = new Error('errorsMap.' + data.err_code)
        message.error(i18next.t('errorsMap.' + data.err_code))
        throw error
      }
      return Promise.resolve([data.data, null])
    },
    [navigate]
  )

  return resolveResponse
}

export const useAuthApi = (isRaw = false) => {
  const { isAuthenticated, getAccessToken } = useLogto()
  const responseHandler = useGetResponseHandler()
  const [loading, setLoading] = useState(false)

  const authFetch = async (input, options) => {
    let token = localStorage.getItem('token')

    if (!isAuthenticated) {
      const accessToken = await getAccessToken(API_URL)
      token = accessToken
    }
    if (!token) {
      token = await getAccessToken(API_URL)
    }
    localStorage.setItem('token', token)

    setLoading(true)
    try {
      const response = await request(input, {
        ...options,
        headers: {
          ...options?.headers,
          Authorization: `Bearer ${token}`,
        },
        timeout: 3 * 60 * 1e3,
      })
      setLoading(false)
      return isRaw ? response : responseHandler(response)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  return { authFetch, loading }
}
