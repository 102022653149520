import { EditOutlined } from '@ant-design/icons'
import { Avatar, Button, Divider, Form, Input, Popconfirm, Select, Spin } from 'antd'
import UploadImage from '../../components/upload-img'
import styles from './index.module.css'
import PrivacyModal from './privacy-modal'
import useLogic from './useLogic'
import { Logout } from '../../layout/sidebar/logout'
import { reportEventTracking } from '@utils/tracking'
import { Language } from '@constants/env'
import { langCache } from '@utils/cache'
import eventBus from '@utils/event'

const FormItemSection = (props) => {
  return (
    <div className={styles.formSec}>
      <div className={styles.title}>{props.title}</div>
      <div>{props.children}</div>
    </div>
  )
}

const UserSetting = () => {
  const {
    user,
    form,
    updateUserHandler,
    inputName,
    setInputName,
    showModal,

    open,
    handleCancel,

    YEAR,
  } = useLogic()

  if (!user) return <Spin />
  return (
    <div className={styles.userSetting}>
      <h1 className="text-3xl mb-4">{_t('settings')}</h1>
      <Form
        form={form}
        name="user_settings"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        labelAlign="left"
        colon={false}
        initialValues={{
          ...user,
          ...user?.info,
          notifications: true,
        }}
      >
        <FormItemSection title={_t('general')}>
          <Form.Item name="language" label={_t('language')}>
            <Select
              className={styles.langSelector}
              style={{ width: 'auto', minWidth: 100, height: 41 }}
              onChange={(language) => {
                updateUserHandler({
                  language,
                }).then(() => {
                  langCache.set(language)
                  eventBus.emit('change_language', language)
                  setTimeout(() => {
                    window.location.reload()
                  }, 50)
                })
              }}
              options={[
                { label: '简体中文', value: Language.CN },
                { label: 'English', value: Language.EN },
              ]}
            />
          </Form.Item>
        </FormItemSection>

        <FormItemSection title={_t('account')}>
          <Form.Item name="picture" style={{ lineHeight: '65px' }} label={_t('avatar')}>
            <UploadImage
              style={{ width: 80, height: 80 }}
              onChange={(profile_image_url) => {
                updateUserHandler({
                  profile_image_url,
                })
              }}
            >
              <Avatar src={user?.avatarUrl} style={{ height: '100%', width: '100%' }} />
            </UploadImage>
            {/* <CameraOutlined /> */}
          </Form.Item>
          <Divider />
          <Form.Item name="name" label={_t('username')}>
            <div className={styles.userName}>
              {user?.info.name || user?.name}
              <Popconfirm
                title={null}
                icon={null}
                description={<Input value={inputName ?? ''} onChange={(e) => setInputName(e.target.value)} />}
                onConfirm={async () => {
                  await updateUserHandler({
                    name: inputName,
                  })
                }}
                okText={_t('confirm')}
                cancelText={_t('cancel')}
              >
                <Button type="text" style={{ paddingLeft: 5 }} shape="round" icon={<EditOutlined />}></Button>
              </Popconfirm>
            </div>
          </Form.Item>
          <Divider />
          <Form.Item name="email" label={_t('email')}>
            {user.email}
          </Form.Item>
        </FormItemSection>

        <Form.Item className="flex items-center justify-center">
          <Logout>
            <Button
              type="primary"
              size="large"
              shape="round"
              style={{ backgroundColor: '#F0F1F1', color: '#1E2336', fontWeight: 600, padding: '20px 30px' }}
              onClick={() => {
                reportEventTracking('click_logout')
              }}
            >
              {_t('logout')}
            </Button>
          </Logout>
        </Form.Item>
      </Form>
      <PrivacyModal open={open} onCancel={handleCancel} />
    </div>
  )
}

export default UserSetting
