export default function Slogan() {
  return (
    <div
      className="mb-8 flex justify-center items-center flex-col"
      style={{ color: '#7E7C99', fontSize: 42, fontWeight: 600 }}
    >
      {/* <Logo /> */}
      <div>{_t('anyAnswer')}</div>
      <div>{_t('questionStart')}</div>
    </div>
  )
}
