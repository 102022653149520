import { Card } from 'antd'
import styles from '../index.module.css'

export default function KbaseCardWrap(props) {
  const { children, ...rest } = props
  return (
    <Card
      hoverable={!!props.onClick}
      bordered={true}
      style={{ width: 340, height: 153, borderRadius: 15, padding: 0 }}
      className={styles.kbaseItem}
      {...rest}
    >
      {children}
    </Card>
  )
}
