import { isDev } from '@constants/env'
import ReactDOM from 'react-dom/client'
import './index.css'
import './utils/sentry'

import App from './app'

async function enableMocking() {
  if (isDev) {
    window.__dev_tools = {
      toggleMock() {
        const isEnabled = this.isMockEnabled()
        if (isEnabled) {
          localStorage.removeItem('mock')
          console.log('[dev] mock disenabled!')
        } else {
          localStorage.setItem('mock', 'true')
          console.log('[dev] mock enabled!')
        }
        location.reload()
      },
      isMockEnabled() {
        return !!localStorage.getItem('mock')
      },
    }
  }

  if (!isDev || !localStorage.getItem('mock')) {
    return
  }

  const { worker } = await import('../mocks/browser')

  const el = document.createElement('div')
  el.innerHTML = `<div style="position: fixed; right: 10px; bottom: 50px;font-size: 14px;
    background: red;
    padding: 5px;
    border-radius: 4px;
    color: white;" onclick="window.__dev_tools.toggleMock()">Enable Mocking</div>`
  document.body.appendChild(el)
  return worker.start()
}

enableMocking().then(main)

function main() {
  ReactDOM.createRoot(document.getElementById('root')!).render(<App />)
}
