import { LinkOutlined } from '@ant-design/icons'
import { useEffect, useRef, useState } from 'react'

function FaviconImage({ iconLink }) {
  const [isImageError, setIsImageError] = useState(false)
  const timer = useRef(null)
  const [faviconUrl, setFaviconUrl] = useState(iconLink)

  useEffect(() => {
    const fetchFavicon = async () => {
      try {
        // Try to get the favicon from the root domain
        const domain = new URL(iconLink).origin
        const rootFavicon = iconLink

        // Check if the root favicon exists
        const response = await fetch(iconLink, { method: 'HEAD' })
        if (response.ok) {
          setFaviconUrl(rootFavicon)
          return
        }

        // If root favicon doesn't exist, try to parse the HTML
        const htmlResponse = await fetch(iconLink)
        const html = await htmlResponse.text()
        const parser = new DOMParser()
        const doc = parser.parseFromString(html, 'text/html')

        // Check for link tags with rel="icon" or rel="shortcut icon"
        const _iconLink = doc.querySelector('link[rel="icon"], link[rel="shortcut icon"]')
        if (_iconLink) {
          const href = _iconLink.getAttribute('href')
          setFaviconUrl(new URL(href, domain).href)
          return
        }

        // If no favicon is found, use a default or placeholder
        setFaviconUrl(_iconLink)
      } catch (error) {
        console.error('Error fetching favicon:', error)
        setFaviconUrl(iconLink)
      }
    }

    // timer.current = setTimeout(() => {
    //   setIsImageError(true)
    // }, 1e3)

    // return () => {
    //   clearInterval(timer.current)
    // }
  }, [])

  if (isImageError) {
    return <LinkOutlined style={{ fontSize: 18 }} />
  }

  return (
    <img
      src={faviconUrl}
      width={25}
      height={25}
      onLoad={() => {
        clearInterval(timer.current)
      }}
      onError={() => setIsImageError(true)}
      alt="Favicon"
    />
  )
}

export default FaviconImage
