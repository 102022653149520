import { Divider, Layout, Menu, MenuProps } from 'antd'
import { useNavigate } from 'react-router-dom'
import routes from '../../constants/routes'
import UploadList from '../components/upload-list'
import UploadProgress from '../components/upload-progress'
import UserAvatar from '../components/user-avatar'
import styles from './index.module.scss'
import SearchTopics from './search-topics'
import useLogic from './useLogic'
import SearchTip from '../components/tip'
import React from 'react'
import { reportEventTracking } from '@utils/tracking'

const { Sider } = Layout

export default function PageHeader() {
  const { user, location, items, menus, tempTopics, uploadingTask, visibleUploadList } = useLogic()
  const navigate = useNavigate()
  const [showTip, setShowTip] = React.useState(false)

  const onClick: MenuProps['onClick'] = (e) => {
    reportEventTracking('click_top_menus', { name: e.key })
    navigate(e.key)
  }

  const bottomMenus = [
    {
      key: 'upload-placeholder',
      label: <div id="upload-history-wrapper"></div>,
      isPlaceholder: true,
    },
    uploadingTask && {
      key: 'upload-list',
      disabled: true,
      label: <UploadProgress task={uploadingTask} />,
    },
    {
      key: '/user/setting',
      label: <UserAvatar user={user} />,
      ...items,
    },
  ].filter(Boolean)

  React.useEffect(() => {
    setShowTip(!!tempTopics.length)
  }, [tempTopics])

  return (
    <Sider width={234} className={styles.sider}>
      <div className={styles.topMenus}>
        <Menu
          mode="inline"
          defaultSelectedKeys={[routes.HOME]}
          selectedKeys={[location.pathname]}
          style={{
            backgroundColor: 'transparent',
            fontWeight: 500,
            border: 'none',
          }}
          items={menus}
          onClick={onClick}
        />
        {!visibleUploadList && (
          <div
            style={{
              margin: '0 auto',
              width: '90%',
              minWidth: '90%',
            }}
          >
            <Divider
              style={{
                margin: '10px auto 20px',
              }}
            />
            <SearchTip open={showTip}>
              <div className={styles.logsTitle} id="temp-search-wrapper">
                <div className={styles.tempSearch}>{_t('tempSearch')}</div>
                <div style={{ float: 'right' }}>{tempTopics.length}</div>
              </div>
            </SearchTip>
          </div>
        )}
      </div>

      {visibleUploadList && <UploadList />}

      {!visibleUploadList && (
        <>
          <SearchTopics topics={tempTopics} />
          <div className={styles.btmWrap}>
            {bottomMenus.map((menu) => {
              return (
                <div key={menu.key} className={menu.isPlaceholder ? '' : styles.btmMenu}>
                  {menu.label}
                </div>
              )
            })}
          </div>
        </>
      )}
    </Sider>
  )
}
