export const xmlDomParse = (input) => {
  const cleanedString = input.replace(/\\n/g, '\n')

  const parser = new DOMParser()
  const xmlDoc = parser.parseFromString(cleanedString, 'text/xml')

  const tElements = xmlDoc.querySelectorAll('t')

  const questionList: any = []
  Array.from(tElements).forEach(($t) => {
    const title = $t.querySelector('k').textContent
    const questions = Array.from($t.querySelectorAll('q'))?.map((q) => q.textContent) ?? []
    questionList.push({ title, questions })
  })

  return questionList
}

export const xmlParser = (input) => {
  const output = []
  const keywords = []

  let cur = ''
  for (let i = 0; i < input?.length; i++) {
    let ch = input[i]
    if (ch === '<') {
      let tag = ['<']
      while (ch !== '>' && i < input.length) {
        i++
        ch = input[i]
        if (ch === '<') {
          cur += tag.join('')
          //reset
          tag = []
        }
        tag.push(ch)
      }

      const tagStr = tag.join('')
      switch (tagStr) {
        case '<r>':
          break
        case '</r>':
          cur && output.push(cur)
          cur = ''
          break
        case '<s>':
          break
        case '</s>':
          cur && output.push(cur)
          cur = ''
          break
        case '<k>':
          break
        case '</k>':
          cur &&
            keywords.push(
              ...cur
                .split(',')
                .map((k) => k.trim())
                .filter(Boolean)
            )
          cur = ''
          break
        default:
          cur += tagStr
      }
    } else {
      cur += ch
    }
  }
  if (cur !== '') {
    output.push(cur)
  }

  return { output, keywords }
}

export function isFromHistory(data) {
  return data?._from === 'history'
}
