import AskMoreIcon from '@assets/icons/ask-more.svg'
import MoreAnsIcon from '@assets/icons/more-ans.svg'
import SparkIcon from '@assets/icons/spark.svg'
import { Button, Divider, Popover, Space } from 'antd'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { MarkdownReply } from '../../../../../components/reply-md'
import styles from '../index.module.scss'
import AskMoreAnswer from './ask-more-answer'
import MoreQuestions from './more-question'
import type { IAnswerData } from '../types'
import useNewQuestion from '../../../hooks/useNewQuestion'
import { reportEventTracking } from '@utils/tracking'

export default function AnswerBlock(props: IAnswerData) {
  const { data } = props
  const { answer } = data
  const [isAskMoreAnsVisible, setIsAskMoreAnsVisible] = useState(!!answer._more_details)
  const [isMoreActionsVisible, setIsMoreActionsVisible] = useState(false)
  const [isAskMoreQuestionVisible, setIsAskMoreQuestionVisible] = useState(false)
  const askQuestion = useNewQuestion()
  const btnRef = useRef()
  const blockRef = useRef()
  const moreQuestionBtnRef = useRef()

  const askMoreAnswerHandler = async () => {
    reportEventTracking('answer_block_ask_more_answer.click')
    setIsAskMoreAnsVisible(true)
    setIsMoreActionsVisible(false)
  }

  const askQuestionHandler = async (question) => {
    reportEventTracking('answer_block_ask_recommended_question.click')
    askQuestion(question)
    setIsMoreActionsVisible(false)
    setIsAskMoreQuestionVisible(false)
  }

  const renderAnsBlock = () => {
    return (
      <div ref={blockRef} className={styles.ansBlockWrap}>
        <AnswerSection {...data} isAskMoreAnsVisible={isAskMoreAnsVisible} />
      </div>
    )
  }

  return answer?.ans_blk_id ? (
    <Popover
      placement="leftBottom"
      trigger={'hover'}
      arrow={false}
      onOpenChange={(open) => {
        if (!open) {
          setIsAskMoreQuestionVisible(false)
          setIsMoreActionsVisible(false)
        } else {
          reportEventTracking('search_answer_block_spark_icon.hover')
        }
      }}
      getPopupContainer={() => blockRef.current}
      overlayClassName={styles.ansPopover}
      overlayInnerStyle={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        padding: '0',
        marginRight: '10px',
      }}
      content={
        <Popover
          trigger={'click'}
          placement="rightTop"
          open={isMoreActionsVisible}
          overlayClassName={styles.moreActions}
          getPopupContainer={() => btnRef.current}
          overlayInnerStyle={{
            marginLeft: '25px',
            marginTop: '-29px',
            borderRadius: '12px',
            border: '1px solid #E7E7E7',
            padding: '10px',
            boxShadow: '0px 4px 4.3px 0px #0000000d',
          }}
          content={
            <div ref={moreQuestionBtnRef}>
              <Space split={<Divider type="vertical" />}>
                <Button
                  type="text"
                  disabled={isAskMoreAnsVisible}
                  icon={<MoreAnsIcon />}
                  className={styles.moreAnsBtn}
                  onClick={askMoreAnswerHandler}
                >
                  {_t('ask_more')}
                </Button>

                <Popover
                  placement="bottom"
                  trigger={['click']}
                  overlayClassName={styles.moreQuestions}
                  open={isAskMoreQuestionVisible}
                  content={<MoreQuestions onAsk={askQuestionHandler} {...data} />}
                  arrow={false}
                  getPopupContainer={() => moreQuestionBtnRef.current}
                >
                  <Button
                    type="text"
                    onClick={() => {
                      reportEventTracking('answer_block_ask_more_questions_button.click')
                      setIsAskMoreQuestionVisible((visible) => !visible)
                    }}
                    className={styles.askMoreBtn}
                    icon={<AskMoreIcon />}
                  >
                    {_t('ask_further')}
                  </Button>
                </Popover>
              </Space>
            </div>
          }
          arrow={false}
        >
          <Button
            ref={btnRef}
            type="text"
            onClick={() => {
              setIsMoreActionsVisible((visible) => !visible)
            }}
            shape="circle"
            icon={<SparkIcon />}
            style={{ color: '#2E8EFF' }}
          ></Button>
        </Popover>
      }
    >
      {renderAnsBlock()}
    </Popover>
  ) : (
    renderAnsBlock()
  )
}

export function AnswerSection(props) {
  const { answer, isAskMoreAnsVisible = false } = props

  return (
    <div className={clsx('p-4 mb-4', styles.ansSection)}>
      <MarkdownReply>{typeof answer === 'string' ? answer : answer.reply}</MarkdownReply>
      {isAskMoreAnsVisible && <AskMoreAnswer {...props} />}
    </div>
  )
}
