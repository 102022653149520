import SearchIcon from '@assets/icons/arrow.svg'
import eventBus from '@utils/event'
import { Input, Spin } from 'antd'
import { useEffect, useState } from 'react'

const maxLength = 120
export default function SearchInput(props) {
  const [input, setInput] = useState(props.question ?? '')
  const [isComposing, setIsComposing] = useState(false)
  const [isSearching, setIsSearching] = useState(false)

  const handleCompositionStart = () => {
    setIsComposing(true)
  }

  const handleCompositionEnd = (e) => {
    setIsComposing(false)
    setInput(e.target.value)
  }

  const onSearchHandler = (value) => {
    const val = value?.trim()
    if (!val || isSearching) return
    props.onPressEnter(val.slice(0, maxLength))
    setInput('')
  }

  const handleEnterPress = (event) => {
    if (!isComposing) {
      event.preventDefault()
      event.stopPropagation()

      onSearchHandler(event.target.value)
    }
  }

  const handleInputChange = (value) => {
    setInput(value)
  }

  useEffect(() => {
    const unbind1 = eventBus.onPage('start_searching', () => {
      setIsSearching(true)
    })
    const unbind2 = eventBus.onPage('end_searching', () => {
      setIsSearching(false)
    })
    return () => {
      unbind1()
      unbind2()
    }
  }, [])

  return (
    <div style={{ width: '100%', maxWidth: 720 }}>
      <Input
        placeholder={props.placeholder}
        style={{
          borderRadius: 25,
          maxWidth: 720,
          height: 50,
          padding: '0 15px',
          paddingLeft: 15,
          borderColor: '#DCDCEB',
          boxShadow: 'none',
        }}
        onPressEnter={handleEnterPress}
        value={input}
        onChange={(e) => {
          const value = e.target.value
          props.onChange?.(value)
          handleInputChange(value)
        }}
        onCompositionStart={handleCompositionStart}
        onCompositionEnd={handleCompositionEnd}
        suffix={
          <div
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
              if (isSearching) return
              onSearchHandler(input)
            }}
            style={{ color: input ? '#1E2336' : '#9F9F9F', cursor: 'pointer' }}
          >
            <Spin spinning={isSearching}>
              <SearchIcon />
            </Spin>
          </div>
        }
      />
      {input?.length >= maxLength - 10 && (
        <div style={{ float: 'right', marginTop: 10, marginRight: 17 }}>
          {input?.length > maxLength && (
            <label style={{ color: '#7e7c99', marginRight: 9 }}>{_t('queryLimitWarning', { maxLength })}</label>
          )}
          <label style={input?.length > maxLength ? { color: '#F47147' } : {}}>{input.length}</label>/{maxLength}
        </div>
      )}
    </div>
  )
}
