import { IOriginalDict } from '@apis/generated/model'
import routes from '@constants/routes'
import { useLogto } from '@logto/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { eventsMap } from '../../constants/events'
import { CacheKey } from '../../types'
import { IMPORT_TYPE } from '../../types/kbase'
import eventBus from '../../utils/event'
import { isImportDone } from '../../utils/kbase'
import { reportEventTracking } from '@utils/tracking'

export default function useLogic() {
  const navigate = useNavigate()
  const { isAuthenticated } = useLogto()
  const [isUploading, setIsUploading] = React.useState(false)
  const [uploadInfo, setUploadInfo] = React.useState<IOriginalDict & { _importType?: IMPORT_TYPE }>(null)

  const searchHandler = (keyword: string) => {
    reportEventTracking('trigger_home_page_search')
    if (!keyword?.trim()) return
    navigate(`${routes.TEMP_SEARCH}?question=${encodeURIComponent(keyword)}`)
  }

  React.useEffect(() => {
    const unbindStart = eventBus.on(eventsMap.CREATE_KBASE_LINK_START, (data) => {
      onUploadStatusChange('start', data)
    })
    const unbindSuccess = eventBus.on(eventsMap.CREATE_KBASE_DONE, (data) => {
      onUploadStatusChange('success', data)
    })

    return () => {
      unbindStart()
      unbindSuccess()
    }
  }, [])

  const onUploadStatusChange = (status: string, extra?: any) => {
    switch (status) {
      case 'start':
        setUploadInfo((info) => ({ ...info, ...extra }))
        setIsUploading(true)
        break
      case 'done':
      case 'error':
        setIsUploading(false)
        break
      case 'success':
        setUploadInfo((info) => ({ ...info, ...extra }))
        break
      default:
        setIsUploading(false)
    }
  }

  React.useEffect(() => {
    if (uploadInfo?.rid) {
      localStorage.setItem(CacheKey.UPLOAD_INFO, JSON.stringify(uploadInfo))
    }
    const unbind = eventBus.on(eventsMap.DELETE_UPLOAD_TASK, () => {
      localStorage.removeItem(CacheKey.UPLOAD_INFO)
      setUploadInfo(null)
    })

    const unbindDone = eventBus.on(eventsMap.UPDATE_IMPORTING_TASK_DATA, (data) => {
      setIsUploading(!isImportDone(data))
      setUploadInfo(data)
    })
    return () => {
      unbind()
      unbindDone()
    }
  }, [uploadInfo?.rid])

  const isFile = uploadInfo?.imported_type === 'file'
  return {
    searchHandler,
    onUploadStatusChange,
    isCreatingLink: isUploading && uploadInfo?._importType === IMPORT_TYPE.LINK,
    isCreatingFile: isUploading && isFile,
    isAuthenticated,
    isUploading,
    progressText: `${isFile ? _t('file') : _t('link')}${_t('importing')}`,
  }
}
