import eventBus from '@utils/event'
import { reportEventTracking } from '@utils/tracking'
import { Space, Typography } from 'antd'
import { useCallback, useMemo } from 'react'
import FaviconImage from '../favicon-image'
import AnswerBlock from './answer-block'
import styles from './index.module.scss'
import KeywordsView from './keywords'
import TableView from './table-view'
import type { IOutputProps } from './types'
import { ReferenceItem } from '../../components/references'

export function AnswerOutputRenderer(props: IOutputProps) {
  const { answer, error, loading, keywords } = props
  // const [sections, setSections] = React.useState([])
  // const [keywords, setKeywords] = React.useState([])

  // const parseSections = React.useCallback(async (input) => {
  //   try {
  //     const { output, keywords } = xmlParser(input)
  //     setSections(output)
  //     setKeywords(keywords)
  //   } catch (err) {
  //     console.error('Error parsing XML:', err)
  //   }
  // }, [])

  // React.useEffect(() => {
  //   if (answer) {
  //     parseSections(answer)
  //   }
  // }, [answer])

  return (
    <div>
      {error ? (
        <div>{error}</div>
      ) : !answer && !props.answers?.length && !loading ? (
        <div>{_t('noAnswer')}</div>
      ) : (
        <div>
          {props.answers?.map((answer, i) => (
            <AnswerBlock key={`${i}_${answer._key}}`} data={{ answer, response_id: props.response_id }} />
          ))}
        </div>
      )}
      {/* {!!keywords?.length && (
        <div>
          <Space>
            {keywords.map((k) => (
              <Link
                className="cursor-pointer"
                key={k}
                href={`${routes.TEMP_SEARCH}?question=${encodeURIComponent(k)}`}
                target="_blank"
              >
                {k}
              </Link>
            ))}
          </Space>
        </div>
      )} */}
    </div>
  )
}

const getIconLink = (url) => {
  try {
    const iconLink = new URL(url ?? '')
    return `${iconLink.origin}/favicon.ico`
  } catch {
    return ''
  }
}

function References(props: IOutputProps) {
  const refs = useMemo(
    () =>
      props?.references
        .map((ref) => {
          if (!ref.url) return null
          if (!ref.icon) {
            ref.icon = getIconLink(ref.url ?? '')
          }
          return ref
        })
        .filter(Boolean),
    [props.references]
  )

  const viewMoreHandler = useCallback(() => {
    reportEventTracking('search_view_more_link.click')
    eventBus.emitOnPage('view_more', refs)
  }, [refs])

  return (
    <div className="mt-6">
      <div className="flex mb-2 px-3 py-0">
        <div className={styles.refLeft}>{_t('key_links', { count: refs?.slice(0, 2).length })}</div>
        <div className={styles.refRight} onClick={viewMoreHandler}>
          {_t('view_all_links', props)}
        </div>
      </div>
      <div className="flex" style={{ gap: 20, marginTop: -20 }}>
        {refs?.slice(0, 2).map((ref) => {
          return <ReferenceItem key={ref.url} {...ref} />
        })}
      </div>
    </div>
  )
}

export function WebAnswerRenderer(props: { data: IOutputProps }) {
  
  return (
    <div className={styles.ansWrapper}>
      <AnswerOutputRenderer {...props.data} />
      <>
        <TableView {...props.data} />
        {(props.data?._from === 'history' || props?.done) && <KeywordsView {...props.data} />}
      </>
      {props.data?.references?.length > 0 && <References {...props.data} />}
    </div>
  )
}

export function KbaseAnswerRenderer(props: { data: IOutputProps }) {
  const kbase = props.data?.kbase
  return (
    <div>
      {kbase?.description && (
        <div style={{ marginLeft: 16 }}>
          <div style={{ color: '#1E2336', fontSize: 16 }}>{_t('view_from_perspective', kbase)}</div>
          <Typography.Paragraph>
            <blockquote style={{ color: '#737373A3', fontSize: 16, borderInlineStartWidth: 2 }}>
              {kbase.description}
            </blockquote>
          </Typography.Paragraph>
        </div>
      )}

      <AnswerOutputRenderer {...props.data} />
    </div>
  )
}

export default function AnswerRenderer(props) {
  if (!props.data) return null

  if (props.data?.kbase?.kid) {
    return <KbaseAnswerRenderer {...props} />
  }

  return <WebAnswerRenderer {...props} />
}
