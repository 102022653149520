import { Button, Divider, Modal, ModalProps, Typography } from 'antd'
export default function PrivacyModal(props: ModalProps) {
  return (
    <Modal
      title={_t('privacyTerms')}
      width={790}
      footer={[
        <div key="close">
          <Divider />
          <Button onClick={props.onCancel} shape="round">
            关闭
          </Button>
        </div>,
      ]}
      {...props}
    >
      <Typography.Text>
        <Typography.Paragraph>{_t('effectiveDate')}</Typography.Paragraph>
        <Typography.Paragraph>
          {_t('privacyUpdateNotice')}
        </Typography.Paragraph>
        <Typography.Paragraph>
          OpenAI
          OpCo,LLC（与我们的关联公司合称为“OpenAI”、“我们”、“我们的”或“我们”）尊重您的隐私，并坚决致力于保护我们从您处获得或关于您的任何信息的安全。本隐私政策描述了我们在您使用我们的网站、应用程序和服务（统称为“服务”）时从您处收集或关于您的个人信息的做法。本隐私政策不适用于我们代表我们的业务产品（例如我们的
          API）的客户处理的内容。我们对这些数据的使用受涵盖对这些产品的访问和使用的客户协议的约束。
        </Typography.Paragraph>
        <Typography.Paragraph>
          有关我们如何收集和使用训练信息来开发为 ChatGPT
          和其他服务提供支持的语言模型，以及您对该信息的选择的信息，请参阅此帮助中心文章（在新窗口中打开）。
        </Typography.Paragraph>
        <Typography.Paragraph>
          <ol>
            <li>
              我们收集的个人信息我们收集与您相关的个人信息（“个人信息”）如下：
              您提供的个人信息：如果您创建帐户以使用我们的服务或与我们进行以下沟通，我们会收集个人信息：
              <ul>
                <li>
                  帐户信息：当您在我们这里创建帐户时，我们将收集与您的帐户相关的信息，包括您的姓名、联系信息、帐户凭证、支付卡信息和交易历史记录（统称为“帐户信息”）。
                </li>
                <li>
                  用户内容：当您使用我们的服务时，我们会收集您向我们的服务提供的输入、文件上传或反馈中包含的个人信息（“内容”）。 
                </li>
                <li>
                  通讯信息：如果您与我们通讯，我们会收集您的姓名、联系信息以及您发送的任何消息的内容（“通讯信息”）。
                </li>
                <li>
                  社交媒体信息：我们在Instagram、Facebook、Medium、Twitter、YouTube 和
                  LinkedIn等社交媒体网站上设有页面。当您与我们的社交媒体页面互动时，我们将收集您选择提供给我们的个人信息，例如您的联系方式（统称为“社交信息”）。此外，托管我们社交媒体页面的公司可能会向我们提供有关我们社交媒体活动的汇总信息和分析。
                </li>
                <li>
                  您提供的其他信息：我们收集您可能向我们提供的其他信息，例如当您参加我们的活动或调查或向我们提供信息以确认您的身份时（统称为“您提供的其他信息”
                  我们从您对服务的使用中自动收到的个人信息：当您访问、使用或与服务互动时，我们会收到有关您的访问、使用或互动的以下信息（“技术信息”）：
                </li>
                <li>
                  日志数据：您使用我们的服务时，您的浏览器或设备自动发送的信息。日志数据包括您的Internet协议地址、浏览器类型和设置、您的请求日期和时间以及您与我们的服务的互动方式。
                </li>
                <li>
                  使用数据：我们可能会自动收集有关您使用服务的信息，例如您查看或参与的内容类型、您使用的功能和采取的操作，以及您的时区、国家、访问日期和时间、用户代理和版本、计算机或移动设备的类型以及您的计算机连接。
                </li>
                <li>
                  设备信息：包括设备名称、操作系统、设备标识符和您使用的浏览器。收集的信息可能取决于您使用的设备类型及其设置。
                </li>
                <li>
                  Cookie：我们使用
                  Cookie来运营和管理我们的服务，并改善您的体验。“Cookie”是您访问的网站向您的浏览器发送的一条信息。您可以将浏览器设置为接受所有
                  Cookie、拒绝所有 Cookie，或在提供 Cookie
                  时通知您，以便您每次都可以决定是否接受它。但是，在某些情况下，拒绝Cookie可能会阻止您使用网站或网站的某些区域或功能，或对其显示或功能产生负面影响。有关Cookie
                  的更多详细信息，请访问关于 Cookie的所有信息（在新窗口中打开）。
                </li>
                <li>
                  分析：我们可能会使用各种在线分析产品，这些产品使用
                  cookie来帮助我们分析用户如何使用我们的服务并增强您在使用服务时的体验。
                </li>
              </ul>
            </li>
            <li>
              我们如何使用个人信息 我们可能将个人信息用于以下目的：
              <ul>
                提供、管理、维护和/或分析服务； 改进我们的服务并开展研究；
                与您沟通；包括向您发送有关我们的服务和活动的信息； 开发新的项目和服务；
                防止欺诈、犯罪活动或滥用我们的服务，并保护我们的 IT 系统、架构和网络的安全；
              </ul>
            </li>
          </ol>
        </Typography.Paragraph>
      </Typography.Text>
    </Modal>
  )
}
