import { useWebSearch } from '@apis/hooks/search'
import React from 'react'
import { AuthContext } from '../../../layout/components/auth-provider'
import { getCacheManager } from '../../../utils'
import { getLang } from '../../../utils/request'

const cacheManager = getCacheManager('global_web_search')

export default function useWebQuery() {
  const { webSearch, loading, data, setData } = useWebSearch()
  const { user } = React.useContext(AuthContext)

  // TODO: abandon prev request if there's a new request
  const onWebQuery = React.useCallback(
    async (question, token) => {
      if (!question?.trim()) return

      return new Promise((resolve) => {
        const ret = cacheManager.get(question)
        // if (ret) {
        // output.token = idx
        // resolve(output);
        //   setData(ret);
        //   return;
        // }
        setData((data) => ({
          ...data,
          token,
        }))
        webSearch({
          keyword: question,
          language: user?.info?.language || getLang(),
        })
          .then(() => {
            setData((output) => {
              output.token = token
              cacheManager.set(question, output)
              resolve(output)
              return output
            })
          })
          .catch((error) => {
            console.error(error)
            setData(null)
          })
      })
    },
    [user]
  )

  return { answer: data, setAnswer: setData, loading, onWebQuery }
}
