import { adminLogin } from '../../apis/hooks/user'

const KEY = 'admin_auth_token'
export async function validateAdminHandler(values, callback) {
  const resp = await adminLogin(values)
  const isPassed = resp.message === 'Ok'
  if (isPassed) {
    sessionStorage.setItem(KEY, resp.data)
  } else {
    sessionStorage.removeItem(KEY)
  }
  callback?.(isPassed)
}
