import FavButton from '@components/fav-button'
import SearchInput from '@components/search-input'
import { Affix } from 'antd'
import SearchResult from './components/search-result'
import useSearchLogic from './useLogic'
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import routes from '@constants/routes'
import References from './components/references'
import eventBus from '@utils/event'
import clsx from 'clsx'
import styles from './index.module.css'
// import { SearchContextProvider, useSearchContext } from './hooks/useSearchContext'

export default function SearchPage() {
  const { currentTopic, questions, onSearch, topicId, question } = useSearchLogic()
  const ref = React.useRef(null)
  const [container, setContainer] = React.useState<HTMLDivElement | null>(null)
  const location = useLocation()
  const navigate = useNavigate()
  const [refs, setRefs] = useState([])

  React.useEffect(() => {
    if (currentTopic || !!question || !!topicId) return

    if (questions.length === 0 && routes.TEMP_SEARCH === location.pathname) {
      navigate(routes.HOME, { replace: true })
    }
  }, [questions, location, topicId, currentTopic])

  React.useEffect(() => {
    setRefs([])
  }, [topicId])

  React.useEffect(() => {
    setContainer(ref.current)
    const unbind1 = eventBus.onPage('view_more', setRefs)
    const unbind2 = eventBus.onPage('hide_view_more', () => {
      setRefs([])
    })

    return () => {
      unbind1()
      unbind2()
    }
  }, [])

  return (
    <div className="flex">
      <div className={clsx(styles.searchWrap)} ref={ref}>
        <div className={clsx('mx-auto', styles.innerWrap)} id="searchWrap">
          <Affix target={() => container}>
            <div className="mb-4 flex items-center bg-white" style={{ padding: '15px', marginTop: -5 }}>
              <SearchInput
                placeholder={
                  questions.length || currentTopic?._history?.length > 0 ? _t('continueAsking') : _t('enterNewQuestion')
                }
                onPressEnter={(value) => {
                  setRefs([])
                  onSearch(value)
                  ref.current?.scrollTo(0, 0)
                }}
              />
              <FavButton
                topic={currentTopic}
                trackingName="click_fav_topic_button_on_search_bar"
                style={{ marginLeft: 10 }}
              />
            </div>
          </Affix>
          <div>
            {questions.map(({ key, ...item }) => (
              <SearchResult key={key} {...item} />
            ))}
            {!currentTopic?._isNewSearch &&
              currentTopic?._history?.map((ans) => <SearchResult key={ans.response_id} cachedAnswer={ans} />)}
          </div>
        </div>
      </div>
      {refs?.length > 0 && <References list={refs} />}
    </div>
  )
}

// export default function SearchPageWrapper() {
//   return (
//     <SearchContextProvider>
//       <SearchPage />
//     </SearchContextProvider>
//   )
// }
