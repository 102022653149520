import { ICandidateData, IQueryData, ITopicHistoryDict, IWebSearchData } from '../apis/generated/model'

export interface IImportFilePayload {
  file: File
}

export enum CacheKey {
  LOADING = 'create_loading',
  UPLOAD_INFO = 'create_upload_info',
  UPLOAD_PROGRESS = 'create_upload_progress',
}

export interface IChatRecord {
  _uuid?: string
  candidates?: ICandidateData[]
  answers?: Array<IWebSearchData | IQueryData>
}

export interface ITopicData {
  topic?: ITopicHistoryDict
  chatHistory?: Array<IChatRecord>
}
