import * as G6 from '@antv/g6'
import mermaid from 'mermaid'
import { useEffect, useRef } from 'react'

function mermaidToG6(mermaidData) {
  const lines = mermaidData.split('\n')
  const nodes = []
  const edges = []

  lines.forEach(function (line) {
    let match = line.match(/(\w+)\[(.*?)\]/)
    if (match) {
      nodes.push({ id: match[1], label: match[2] })
    }

    match = line.match(/(\w+)\s*-->\s*\|\s*?(.*?)\s*\|\s*?(\w+)/)
    if (match) {
      edges.push({ source: match[1], target: match[3], label: match[2] })
    }
  })

  return { nodes: nodes, edges: edges }
}

// Example function to convert Mermaid syntax to G6-compatible format
async function convertMermaidToG6(mermaidData) {
  // Placeholder for your parsed data
  const parsedData = mermaidToG6(mermaidData) // Implement a parsing function or use a library

  return parsedData
  const nodes = parsedData.nodes.map((node) => ({
    id: node.id,
    label: node.label,
    // Additional G6 styling options here
  }))

  const edges = parsedData.edges.map((edge) => ({
    source: edge.source,
    target: edge.target,
    label: edge.label || '',
    // Additional G6 edge styling options here
  }))

  return { nodes, edges }
}

function dataConverter(data) {
  if (data?.connections?.length) {
    const edges = data.connections.map((edge) => {
      return {
        source: edge.from,
        target: edge.to,
        label: edge.description || edge.linkText || '',
      }
    })
    data.edges = edges
  }

  data.nodes.forEach(node=>{
    node.data = node
  })

  return data
}

export default function G6Render(props) {
  const ref = useRef()

  useEffect(() => {
    if (!props.input) return
    // Convert Mermaid data and load into G6
    const graph = new G6.Graph({
      container: ref.current,
      autoFit: 'view',
      data: dataConverter(props.input),
      //   layout: {
      //     type: 'antv-dagre',
      //     nodeSize: [60, 30],
      //     nodesep: 60,
      //     ranksep: 40,
      //     controlPoints: true,
      //   },
      //   node: {
      //     type: 'rect',
      //     style: {
      //       size: [60, 30],
      //       radius: 8,
      //       labelText: (d) => d.id,
      //       labelBackground: true,
      //     },
      //   },
      //   edge: {
      //     type: 'polyline',
      //     style: {
      //       router: {
      //         type: 'orth',
      //       },
      //       labelText: (d) => d.label,
      //     },
      //   },
      //   behaviors: ['drag-element', 'drag-canvas', 'zoom-canvas'],

      node: {
        labelCfg: {
          offsetY: 50,
          offsetX: 50,
        },
        style: {
          labelText: (d) => d.label,
          // fill: '#FFFFFF',
          labelPlacement: 'right',
          labelBackground: true,
          ports: [{ placement: 'right' }, { placement: 'left' }],
        },
        animation: {
          enter: false,
        },
      },
      edge: {
        type: 'polyline',
        style: {
          label: true,
          labelBackground: true,
          router: {
            type: 'orth',
          },
          // stroke: '#CCCCCC',
          labelText: (d) => {
            return d.label
          },
        },
        animation: {
          enter: false,
        },
      },
      layout: {
        type: 'mindmap',
        direction: 'LR',
        getHeight: () => 32,
        getWidth: () => 32,
        getVGap: () => 4,
        getHGap: () => 100,
      },
      plugins: ['toolbar'],
      behaviors: ['collapse-expand', 'drag-canvas', 'zoom-canvas', 'click-select'],
    })

    // Load the data and render
    graph.render()

    graph.on('node:click', (evt) => {
      console.log(evt)
      const { item } = evt // 'item' is the node that was clicked
      const model = item.getModel() // Get the data model of the clicked node

      console.log('Node clicked:', model) // Do something with the clicked node's data
    })
  }, [props.input])

  return <div ref={ref} style={{ height: 400, backgroundColor: 'white', padding: 10 }}></div>
}
