// useUser.ts
import { useState, useEffect } from 'react'
import { useLogto, IdTokenClaims } from '@logto/react'
import { useGetUserInfo, useUpdateUserInfo } from '../apis/hooks/user'
import { userInfoCache } from '@utils/cache'

const useUser = () => {
  const [user, setUser] = useState<IdTokenClaims & { info: Record<string, any>; avatarUrl?: string }>()
  const { getUserInfo } = useGetUserInfo()
  const { isAuthenticated, getIdTokenClaims } = useLogto()
  const { updateUserInfo, loading } = useUpdateUserInfo()

  const queryUser = async () => {
    try {
      const cachedUser = userInfoCache.get()
      if (cachedUser?.info) {
        setUser(cachedUser)
        return
      }
    } catch {
      //do nth
    }
    if (loading) return

    const claims = await getIdTokenClaims()
    const userInfo = await getUserInfo()
    const user = { ...claims, info: userInfo }
    user.avatarUrl = user?.info?.profile_image_url || user?.picture
    userInfoCache.set(user)
    setUser(user)
  }

  useEffect(() => {
    if (!isAuthenticated) return

    queryUser()
  }, [isAuthenticated])

  return {
    user,
    updateUserInfo: async (...args) => {
      await updateUserInfo(...args)
      queryUser()
    },
  }
}

export default useUser
