import { List } from 'antd'
import KbaseCardItem from '../../components/kbase-card'
import styles from './index.module.css'
import useLogic from './useLogic'
import KbaseCreationCard from './components/kbase-creation-card'

export default function MyKbase() {
  const { loading, kbases } = useLogic()

  return (
    <div className={styles.myKbase}>
      <div style={{ color: '#9F9F9F', fontSize: 12, marginBottom: 20, marginLeft: 10 }}>{_t('betaVersionLimit')}</div>
      <List
        locale={{
          emptyText: _t('noData'),
        }}
        loading={loading}
        grid={{ gutter: 16 }}
        dataSource={kbases}
        renderItem={(item) => {
          return <List.Item>{item.kid ? <KbaseCardItem key={item.kid} {...item} /> : <KbaseCreationCard />}</List.Item>
        }}
      />
    </div>
  )
}
