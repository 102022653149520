import FileIcon from '@assets/icons/file.svg'
import GrayFileIcon from '@assets/icons/gray-file.svg'
import { Button, message, Tooltip } from 'antd'
import styles from './index.module.scss'
import clsx from 'clsx'
import useLayoutContext from '@hooks/useLayoutContext'
import { useTranslation } from 'react-i18next'

interface IProps {
  isUploading?: boolean
  isCreating?: boolean
  onClick: (event?: any) => void
  active?: boolean
  disabled?: boolean
}
export default function FileImportButton(props: IProps) {
  const { t } = useTranslation()
  const { importingTask } = useLayoutContext()
  const { isUploading, isCreating, onClick, active, disabled } = props

  const isDisabled = isUploading || !!importingTask?._isProcessing || disabled
  return (
    <Tooltip
      color="white"
      overlayInnerStyle={{
        color: '#9F9F9F',
        width: 250,
        fontSize: 12,
        borderRadius: 12,
        padding: 10,
      }}
      placement="top"
      title={
        <ul>
          <li className={styles.ruleTip}>{t('rule_tip_format')}</li>
          <li className={styles.ruleTip}>{t('rule_tip_filesize')}</li>
        </ul>
      }
    >
      <Button
        className={clsx(active && styles.active, styles.fileBtn)}
        loading={isCreating}
        onClick={(event) => {
          if (isDisabled) {
            if (importingTask?._isProcessing) {
              message.info(t('import_validate_msg'))
            }
            event.stopPropagation()
            return
          }
          onClick()
        }}
        type="link"
        disabled={disabled}
      >
        {isDisabled ? <GrayFileIcon /> : <FileIcon />}
        {t('import_file')}
      </Button>
    </Tooltip>
  )
}
