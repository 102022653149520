import apis from '@apis/index'
import { useEventStreamFetch } from '@hooks/useEventStreamFetch'
import { Button, Skeleton, Space, Tabs, Typography } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import styles from '../index.module.scss'
import type { IAnswerProps } from '../types'
import { xmlDomParse } from '../utils'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import eventBus from '@utils/event'
import { reportEventTracking } from '@utils/tracking'
import CakeIcon from '@assets/icons/cake-fill.svg'

function QuestionCategoryTitle(props) {
  const { title, active, ...rest } = props
  return (
    <Button type="text" className={styles.categoryTitle} style={{ color: active ? '#2C2D31' : '#BDC0C1' }} {...rest}>
      {title}
    </Button>
  )
}

function ExtraQuestion(props) {
  const { question, ...rest } = props
  return (
    <Typography.Paragraph ellipsis className={styles.extraQuestion} {...rest}>
      {question}
    </Typography.Paragraph>
  )
}

export default function MoreQuestions(props: IAnswerProps & { onAsk: any }) {
  const { request, data, loading } = useEventStreamFetch(
    apis.transformAnswerToMoreQuestionsApiAnswerTransformMoreQuestionsPost
  )

  const { onAsk } = props
  const [activeTitleIndex, setActiveTitleIndex] = useState(0)
  const [questions, setQuestions] = useState(null)

  const parseChunk = (chunk) => {
    const questions = xmlDomParse(chunk)
    setQuestions(questions)
    setTimeout(() => {
      eventBus.emitOnPage('query_questions')
    }, 50)
  }

  const loadQuestions = async () => {
    request({ response_id: props.response_id, ans_blk_id: props.answer.ans_blk_id })
  }

  useEffect(() => {
    if (props.answer._more_questions) {
      parseChunk(props.answer._more_questions.reply)
      return
    }

    loadQuestions()
  }, [])

  useEffect(() => {
    const chunk = data?.data?.chunk
    if (data?.done && chunk) {
      parseChunk(chunk)
    }
  }, [data?.done])

  useEffect(() => {
    const unbind = eventBus.onPage('send_questions', ({ questions: globalQuestions }) => {
      if (globalQuestions?.length) {
        const questionList = globalQuestions.map((q) => q.question)

        setQuestions((questions) => {
          let isDirty = false
          const list = questions?.filter((item) => {
            item.questions = item.questions?.filter((q) => {
              const flag = !questionList.includes(q)
              if (!isDirty) {
                isDirty = flag
              }
              return flag
            })
            return item
          })
          return isDirty ? list : questions
        })
      }
    })
    return unbind
  }, [questions])

  const items = useMemo(() => {
    return (
      questions?.map(({ title }, index) => {
        return {
          label: <QuestionCategoryTitle title={title} active={activeTitleIndex === index} key={index} />,
          key: index,
          children: null,
        }
      }) ?? []
    )
  }, [questions, activeTitleIndex])

  const currentTabData = questions?.[activeTitleIndex]

  return (
    <div style={{ maxWidth: 395, width: 390 }}>
      {data?.loading || loading ? (
        <Skeleton active />
      ) : (
        <>
          <Tabs
            items={items}
            defaultActiveKey="1"
            onChange={(index) => {
              reportEventTracking('more_questions_tab.click')
              setActiveTitleIndex(+index)
            }}
          />
          <div className="overflow-auto" style={{ padding: '0 7px 7px 7px' }}>
            <Space direction="vertical" style={{ gap: 10 }}>
              {currentTabData?.questions?.length === 0 ? (
                <div
                  style={{ color: '#9F9F9F', fontWeight: 600, fontSize: 14, padding: 10 }}
                  className="flex items-center justify-center flex-col"
                >
                  <div style={{ fontSize: 48, marginTop: 25, marginBottom: 15, transform: 'scale(1.5)' }}>
                    <CakeIcon />
                  </div>
                  <div>{_t('ask_further_done', currentTabData)}</div>
                </div>
              ) : (
                currentTabData?.questions?.map((q) => (
                  <ExtraQuestion
                    question={q}
                    key={q}
                    onClick={() => {
                      reportEventTracking('more_questions_extra_question.click')
                      onAsk(q)
                      setTimeout(() => {
                        eventBus.emitOnPage('query_questions')
                      }, 50)
                    }}
                  />
                ))
              )}
            </Space>
          </div>
        </>
      )}
    </div>
  )
}
